import { createSlice } from '@reduxjs/toolkit'
import { NetworkErrorState } from './actionTypes'
import networkErrorReducer from './networkErrorReducer'

const initialState: NetworkErrorState = {
  connectionError: false,
}

export const networkErrorSlice = createSlice({
  name: 'network_error',
  initialState,
  reducers: networkErrorReducer,
})

export const { setNoConnection } = networkErrorSlice.actions

export default networkErrorSlice.reducer
