import { useEffect } from 'react'
import { i18n } from '@lingui/core'
import { useRouter } from 'next/router'

const useLocale = () => {
  const { locale } = useRouter()

  useEffect(() => {
    const load = async (loc: string) => {
      const data = await import(`../../locales/${loc}/file`)
      const messages = data?.messages

      i18n.load(loc, messages)
      i18n.activate(loc)
    }

    load(locale as string)
  }, [locale])
}

export default useLocale
