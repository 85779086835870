import { useEffect } from 'react'

import { saveUserConsent } from '@api/user'

import { useAuthContext } from '@modules/Auth/AuthProvider'

const CALLBACK_DELAY = 500
export const useSaveUserConsent = () => {
  const { globalUser } = useAuthContext()

  useEffect(() => {
    const onChangeUserConsent = () => {
      setTimeout(async () => {
        try {
          const token = await globalUser?.getIdToken()
          if (!token) return

          const consent = (window as any).google_tag_data?.ics?.entries

          saveUserConsent(token, consent)
        } catch {
          // silence
        }
      }, CALLBACK_DELAY)
    }

    document.addEventListener('cookieyes_consent_update', onChangeUserConsent)

    return () => {
      document.removeEventListener(
        'cookieyes_consent_update',
        onChangeUserConsent
      )
    }
  }, [globalUser])
}
