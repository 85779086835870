import { createSlice } from '@reduxjs/toolkit'

import { AmplitudeState } from '@reduxStore/reducers/amplitude/types'

import reducers from './amplitudeReducer'

const initialState: AmplitudeState = {
  amplitudeInited: false,
}

export const amplitudeSlice = createSlice({
  name: 'amplitude',
  initialState,
  reducers,
})

export const { setAmplitudeInited, setAmplitudeId } = amplitudeSlice.actions

export default amplitudeSlice.reducer
