import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'

import { getChallenge } from '@api/challenges'

import { ChallengeActions } from '@reduxStore/reducers/challenges/types'

import { Challenge } from '@_types/courses'

export const get7DayChallenge = createAsyncThunk<
  Challenge,
  User | null | undefined
>(ChallengeActions.GET_SEVEN_DAY_CHALLENGE, async (user = null) => {
  const token = (await user?.getIdToken()) || ''

  const { data } = await getChallenge({
    token,
    challenge: '7_day_challenge',
  })

  return data
})
