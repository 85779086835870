import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import {
  SourceBusterActions,
  SourceBusterState,
} from '@reduxStore/reducers/sourcebuster/types'

const reducers: SliceCaseReducers<SourceBusterState> = {
  [SourceBusterActions.SET_IS_SOURCE_BUSTER_INITED]: (
    state: SourceBusterState,
    action: PayloadAction<SourceBusterState['sourceBusterInited']>
  ) => ({
    ...state,
    sourceBusterInited: action.payload,
  }),
}

export default reducers
