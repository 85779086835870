import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import {
  ChallengeActions,
  ChallengesState,
} from '@reduxStore/reducers/challenges/types'

export const initialState: ChallengesState = {
  sevenDayChallenge: null,
  sevenDayChallengeExpired: false,
}

const reducers: SliceCaseReducers<ChallengesState> = {
  [ChallengeActions.SET_SEVEN_DAY_CHALLENGE_EXPIRED]: (
    state: ChallengesState,
    action: PayloadAction<ChallengesState['sevenDayChallengeExpired']>
  ) => ({
    ...state,
    sevenDayChallengeExpired: action.payload,
  }),
}

export default reducers
