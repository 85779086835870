import { Trans } from '@lingui/macro'
import { FC } from 'react'

// use direct import for svgs instead of using Icon component
// to have them in offline mode
import CloseIcon from '@promova/ui/icons/ui-kit/close.svg'
import WifiSlashIcon from '@promova/ui/icons/ui-kit/wifi-slash.svg'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'

import { setNoConnection } from '@features/NoConnectionAlert/networkErrorSlice'

import SnackBar from '@elements/SnackBar/SnackBar'

import styles from './no_connection.module.scss'

const NoConnectionAlert: FC = () => {
  const noConnection = useAppSelector(
    (state) => state?.networkErrors?.connectionError
  )
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(setNoConnection(false))
  }

  if (noConnection) {
    return (
      <SnackBar className={styles.no_connection_error}>
        <div className={styles.row}>
          <WifiSlashIcon className={styles.icon} />
          <div>
            <div className={styles.title}>
              <Trans>No internet connection</Trans>
            </div>
            <div className={styles.description}>
              <Trans>Some content might be missing</Trans>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className={styles.close_btn}
          >
            <CloseIcon className={styles.close_icon} />
          </button>
        </div>
      </SnackBar>
    )
  }

  return null
}

export default NoConnectionAlert
