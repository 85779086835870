import Script from 'next/script'
import { FC } from 'react'

const ConsentModeScript: FC = () => (
  <Script
    id="consent_mode"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }

          gtag("consent", "default", {
            ad_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 2000,
            region: [
              'BE',
              'ES',
              'HU',
              'SK',
              'BG',
              'FR',
              'MT',
              'FI',
              'CZ',
              'HR',
              'NL',
              'SE',
              'DK',
              'IT',
              'AT',
              'DE',
              'CY',
              'PL',
              'IS',
              'EE',
              'LV',
              'PT',
              'LI',
              'IE',
              'LT',
              'RO',
              'NO',
              'EL',
              'LU',
              'SI',
              'GB'
            ],
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_p  assthrough", true);
        `,
    }}
  />
)

export default ConsentModeScript
