import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import {
  getActiveCourse,
  getCourseProgress,
  getCoursesData,
  getDailyPlan,
  getDailyPlanProgress,
  getLesson,
  setLessonProgress,
} from '@reduxStore/reducers/courses/coursesThunks'
import { CoursesState } from '@reduxStore/reducers/courses/types'

const extraReducers = (
  builder: ActionReducerMapBuilder<CoursesState>
): void => {
  builder
    .addCase(getActiveCourse.pending, (state) => ({
      ...state,
      course: undefined,
      loading: true,
      loadingError: false,
    }))
    .addCase(getActiveCourse.fulfilled, (state, action) => ({
      ...state,
      course: action.payload.course,
      lessonAnalytics: {
        ...state.lessonAnalytics,
        ...action.payload.analytics,
      },
      loading: false,
      loadingError: false,
    }))
    .addCase(getActiveCourse.rejected, (state) => ({
      ...state,
      loading: false,
      loadingError: true,
    }))
    .addCase(getCourseProgress.pending, (state) => ({
      ...state,
      loadingError: false,
      courseProgress: undefined,
      lesson: null,
    }))
    .addCase(getCourseProgress.fulfilled, (state, action) => ({
      ...state,
      courseProgress: action.payload,
      loadingError: false,
    }))
    .addCase(getCourseProgress.rejected, (state) => ({
      ...state,
      loadingError: true,
    }))
    .addCase(getDailyPlan.pending, (state) => ({
      ...state,
      loadingError: false,
    }))
    .addCase(getDailyPlan.fulfilled, (state, action) => ({
      ...state,
      dailyPlan: action.payload,
      loadingError: false,
    }))
    .addCase(getDailyPlan.rejected, (state) => ({
      ...state,
      loadingError: true,
    }))
    .addCase(getDailyPlanProgress.pending, (state) => ({
      ...state,
      dailyPlanProgress: undefined,
      loadingError: false,
      lesson: null,
    }))
    .addCase(getDailyPlanProgress.fulfilled, (state, action) => ({
      ...state,
      dailyPlanProgress: action.payload,
      loadingError: false,
    }))
    .addCase(getDailyPlanProgress.rejected, (state) => ({
      ...state,
      loadingError: true,
    }))
    .addCase(getCoursesData.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addCase(getLesson.pending, (state) => ({
      ...state,
      lesson: null,
      loadingError: false,
    }))
    .addCase(getLesson.fulfilled, (state, action) => ({
      ...state,
      lesson: action.payload,
      loadingError: false,
    }))
    .addCase(getLesson.rejected, (state) => ({
      ...state,
      lesson: null,
      loadingError: true,
    }))
    .addCase(setLessonProgress.rejected, (state) => ({
      ...state,
      progressError: true,
    }))
}

export default extraReducers
