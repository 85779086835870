import axios, { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

type CallBack = (
  country: string | null,
  countryCode: string | null,
  currentRegionName: string | null,
  timezone: string | null
) => void

export const getCountry = async (callback: CallBack): Promise<void> => {
  await fetch(
    `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_IP_API_KEY}`
  )
    .then((res) => {
      if (!res.ok) {
        return null
      }
      return res.json()
    })
    .then((data) => {
      if (data.status === 'success') {
        const { country, countryCode, regionName, timezone } = data
        callback(country, countryCode, regionName, timezone)
      }
    })
    .catch((err) => {
      if (err && typeof err.message === 'string') {
        callback(null, null, null, null)
      }
    })
}

interface ResultCountry {
  country: string
  countryCode: string
  region: string
  timezone: string
  error: boolean
}

type locationProp = string | null

const useCountry = (): ResultCountry => {
  const [countryCode, setCountryCode] = useState('')
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [timezone, setTimezone] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    getCountry(
      (
        currentCountry: locationProp,
        code: locationProp,
        currentRegionName: locationProp,
        currentTimezone: locationProp
      ) => {
        if (code !== null) setCountryCode(code)

        if (currentCountry !== null) setCountry(currentCountry)

        if (currentRegionName !== null) setRegion(currentRegionName)

        if (currentTimezone !== null) setTimezone(currentTimezone)

        if (!currentCountry || !code) setError(true)
      }
    )
  }, [])
  // TODO: add cache for this hook
  return { country, countryCode, region, error, timezone }
}

export const getIpData = async (): Promise<
  AxiosResponse<Record<string, any>>
> => {
  const data = await axios.get(
    `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_IP_API_KEY}`
  )

  return data
}

export const getCountryByIp = (
  ip: string
): Promise<{
  country: string | null
  countryCode: string | null
  regionName: string | null
}> =>
  fetch(
    `https://pro.ip-api.com/json/${ip}?key=${process.env.NEXT_PUBLIC_IP_API_KEY}`
  )
    .then((res) => {
      if (!res.ok) {
        return null
      }
      return res.json()
    })
    .then((data) => {
      if (data.status === 'success') {
        const { country, countryCode, regionName } = data
        return { country, countryCode, regionName }
      }
      return { country: null, countryCode: null, regionName: null }
    })
    .catch(() => ({ country: null, countryCode: null, regionName: null }))

interface CountryRes {
  country?: string
  countryCode?: string
}

const countryCache: CountryRes = {}

export const getCountryWithCache = async (): Promise<CountryRes> => {
  if (
    sessionStorage.getItem('country') &&
    sessionStorage.getItem('countryCode')
  ) {
    return {
      country: sessionStorage.getItem('country') as string,
      countryCode: sessionStorage.getItem('countryCode') as string,
    }
  }

  if (countryCache.country && countryCache.countryCode) {
    return countryCache
  }

  try {
    const res = await axios.get(
      `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_IP_API_KEY}`
    )
    const { country, countryCode } = res.data
    countryCache.country = country
    countryCache.countryCode = countryCode
    sessionStorage.setItem('country', country)
    sessionStorage.setItem('countryCode', countryCode)
    return { country, countryCode }
  } catch {
    return {}
  }
}

export default useCountry
