import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import {
  getUserProfile,
  loadUserProducts,
  setUserSettings,
  updateKeeper,
  updateUserProfile,
} from '@reduxStore/reducers/profile/profileThunks'
import { ProfileState } from '@reduxStore/reducers/profile/types'

const extraReducers = (
  builder: ActionReducerMapBuilder<ProfileState>
): void => {
  builder
    .addCase(getUserProfile?.fulfilled, (state, action) => ({
      ...state,
      userProfile: action.payload?.userProfile,
      settings: {
        ...state.settings,
        ...action.payload?.userSettings,
      },
    }))
    .addCase(getUserProfile.rejected, (state) => ({
      ...state,
    }))
    .addCase(updateKeeper.fulfilled, (state, action) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        keeper: {
          ...state.userProfile?.keeper,
          [action.payload.key]: {
            value: action.payload.value,
            updated_at: `${Date.now()}`,
          },
        },
      },
    }))
    .addCase(updateUserProfile.fulfilled, (state, action) => ({
      ...state,
      userProfile: action.payload?.userProfile,
    }))
    .addCase(loadUserProducts.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addCase(setUserSettings.fulfilled, (state, action) => ({
      ...state,
      settings: action.payload,
    }))
}

export default extraReducers
