import { createSlice } from '@reduxjs/toolkit'

import reducers from '@reduxStore/reducers/sourcebuster/sourceBusterReducer'
import { SourceBusterState } from '@reduxStore/reducers/sourcebuster/types'

const initialState: SourceBusterState = {
  sourceBusterInited: false,
}

export const sourceBusterSlice = createSlice({
  name: 'sourceBuster',
  initialState,
  reducers,
})

export const { setSourceBusterInited } = sourceBusterSlice.actions

export default sourceBusterSlice.reducer
