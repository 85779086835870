import {
  DAILY_PLAN_COMPLETED_COURSE_POPUP_ALREADY_SHOWN,
  DAILY_PLAN_FIRST_LESSON_OPENED_DATE,
  SL_APP_PROMO_SHOWING_COUNT,
  SL_INTRO_LESSON_COMPLETED,
  SL_LAST_COMPLETED_LESSON,
  SL_LAST_OPENED_LESSON,
} from '@constants_folder/storageKeys'

const LOCAL_STORAGE_KEYS_TO_CLEAR: string[] = [
  SL_APP_PROMO_SHOWING_COUNT,
  DAILY_PLAN_COMPLETED_COURSE_POPUP_ALREADY_SHOWN,
  DAILY_PLAN_FIRST_LESSON_OPENED_DATE,
  SL_INTRO_LESSON_COMPLETED,
  SL_LAST_OPENED_LESSON,
  SL_LAST_COMPLETED_LESSON,
]

const clearLocalStorageAfterLogout = () => {
  LOCAL_STORAGE_KEYS_TO_CLEAR.forEach((key) => localStorage.removeItem(key))
}

export default clearLocalStorageAfterLogout
