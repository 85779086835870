import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAvailableProducts } from '@api/billing'
import { addDataToProfile, editProfileV1, getProfile } from '@api/profile'

import {
  ProfileActions,
  ProfileState,
  UserSettings,
} from '@reduxStore/reducers/profile/types'

import { USER_SETTINGS } from '@constants'

import { KeeperData, UserProfile } from '@_types/index'
import { SubscriptionStatus } from '@_types/subscription'

import { setUserProperties } from '@utils/analytics'

export const getUserProfile = createAsyncThunk<
  {
    userProfile: Partial<UserProfile>
    userSettings: Partial<UserSettings>
  },
  string | undefined
>(
  ProfileActions.GET_USER_PROFILE,
  async (
    token = ''
  ): Promise<{
    userProfile: Partial<UserProfile>
    userSettings: Partial<UserSettings>
  }> => {
    const res = await getProfile(token)

    const userProfile = res?.profile as Partial<UserProfile> | undefined

    if (res?.status === 200 && userProfile) {
      let userSettings

      try {
        userSettings = JSON.parse(
          userProfile?.keeper?.[USER_SETTINGS]?.value || '{}'
        )
      } catch {
        //
      }

      return { userProfile, userSettings }
    }

    return { userProfile: {}, userSettings: {} }
  }
)

export const updateKeeper = createAsyncThunk<
  {
    key: string
    value: string
  },
  { token: string; data: KeeperData }
>(
  ProfileActions.UPDATE_USER_KEEPER,
  async (
    { token, data },
    thunkAPI
  ): Promise<{
    key: string
    value: string
  }> => {
    const res = await addDataToProfile(token, data)

    if (res?.status === 200) {
      thunkAPI.dispatch(getUserProfile(token))
    }

    return { key: data.key, value: data.payload }
  }
)

export const updateUserProfile = createAsyncThunk<
  {
    userProfile: Partial<UserProfile>
  },
  { token: string; data: Partial<UserProfile> }
>(
  ProfileActions.UPDATE_USER_PROFILE,
  async ({
    token,
    data,
  }): Promise<{
    userProfile: Partial<UserProfile>
  }> => {
    const res = await editProfileV1(token, data)

    const userProfile = res?.data as Partial<UserProfile> | undefined

    return {
      userProfile: res?.status === 200 && userProfile ? userProfile : {},
    }
  }
)

export const setUserSettings = createAsyncThunk<
  UserSettings,
  { token: string; data: Partial<UserSettings> },
  {
    state: { profile: ProfileState }
  }
>(
  ProfileActions.SET_USER_SETTINGS,
  async ({ token, data }, thunkAPI): Promise<UserSettings> => {
    const prevUserSettings = thunkAPI.getState().profile.settings
    const updatedUserSettings = { ...prevUserSettings, ...data }
    const updatedUserSettingsJson = JSON.stringify(updatedUserSettings)

    await thunkAPI.dispatch(
      updateKeeper({
        token,
        data: { key: USER_SETTINGS, payload: updatedUserSettingsJson },
      })
    )

    return updatedUserSettings
  }
)

type ProductsResult = Omit<
  ProfileState,
  'userProfile' | 'coursesOnboarding' | 'settings'
>
export const loadUserProducts = createAsyncThunk<
  ProductsResult,
  { token: string },
  {
    state: { profile: ProfileState }
  }
>(
  ProfileActions.GET_USER_PRODUCTS,
  async ({ token }, thunkApi): Promise<ProductsResult> => {
    const {
      isSubscriptionFetched,
      hasCoursesPremium,
      hasTutoringSubscription,
      groupLessonsSubscriptionStatus,
    } = thunkApi.getState().profile
    const nextState = {
      isSubscriptionFetched,
      hasCoursesPremium,
      hasTutoringSubscription,
      groupLessonsSubscriptionStatus,
    }

    if (!token) {
      return nextState
    }

    try {
      const { data: userProducts } = await getAvailableProducts(token)

      const activeSubscriptions = userProducts.filter(
        (product) => product?.status === 'ok'
      )

      const activeCoursesSubscription = activeSubscriptions.find(
        (product) => product?.category === 'self study'
      )
      const activeTutorsSubscription = activeSubscriptions.find(
        (product) => product?.category === 'tutors'
      )
      const activeGroupLessonsSubscription = activeSubscriptions.find(
        (product) => product?.type === 'group_lessons'
      )

      const canceledGroupLessonsSubscription = userProducts.some(
        (product) =>
          product?.type === 'group_lessons' && product?.status !== 'ok'
      )

      if (activeSubscriptions.length === 0) {
        setUserProperties({
          subscription_status: SubscriptionStatus.FREE,
        })
      } else {
        const isUserHasPremiumSubscription = Boolean(
          activeSubscriptions.find((sub) => !sub?.trial)
        )

        setUserProperties({
          subscription_status: isUserHasPremiumSubscription
            ? SubscriptionStatus.PREMIUM
            : SubscriptionStatus.TRIAL,
        })
      }

      nextState.hasTutoringSubscription = Boolean(activeTutorsSubscription)

      if (!activeTutorsSubscription) {
        setUserProperties({
          tutoring_subscription_status: SubscriptionStatus.FREE,
        })
      } else {
        const tutoringSubscriptionStatus = activeTutorsSubscription?.trial
          ? SubscriptionStatus.TRIAL
          : SubscriptionStatus.PREMIUM
        setUserProperties({
          tutoring_subscription_status: tutoringSubscriptionStatus,
        })
      }

      if (!activeCoursesSubscription) {
        setUserProperties({
          courses_subscription_status: SubscriptionStatus.FREE,
        })

        nextState.hasCoursesPremium = false
      } else {
        nextState.hasCoursesPremium = true
        /*
          we have 3 subscription statuses :
          if status === ok and trial === true => 'trial'
          if status === ok and trial === false => 'premium'
          else we have 'free'
        */

        const subscriptionStatus = activeCoursesSubscription?.trial
          ? SubscriptionStatus.TRIAL
          : SubscriptionStatus.PREMIUM

        setUserProperties({
          courses_subscription_status: subscriptionStatus,
          courses_paying_user: true,
        })
      }

      if (activeGroupLessonsSubscription) {
        nextState.groupLessonsSubscriptionStatus = 'active'
      } else if (canceledGroupLessonsSubscription) {
        nextState.groupLessonsSubscriptionStatus = 'canceled'
      } else {
        nextState.groupLessonsSubscriptionStatus = 'none'
      }
    } catch {
      setUserProperties({
        courses_subscription_status: SubscriptionStatus.FREE,
      })

      setUserProperties({
        subscription_status: SubscriptionStatus.FREE,
      })
    } finally {
      nextState.isSubscriptionFetched = true
    }

    return nextState
  }
)
