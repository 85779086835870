import axios from 'axios'

import { sendAxiosErrorAnalytics } from '@utils/analytics'

import { noConnectionInterceptor } from '@features/NoConnectionAlert/utils/noConnectionInterceptor'

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  (config) => config,
  sendAxiosErrorAnalytics
)
axiosInstance.interceptors.response.use(
  (config) => config,
  noConnectionInterceptor
)

export default axiosInstance
