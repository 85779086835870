export enum AccessibilitySettingsKeys {
  DYSLEXIC = 'dyslexic',
  WHITE_NOISE = 'whiteNoise',
}

export type AccessibilitySettings = {
  [key in AccessibilitySettingsKeys]: boolean
}

export type BookReaderSettings = {
  theme: string
  fontFamily: string
  fontSize: number
}
