export const BOOKED_LESSONS_COUNT = 'booked_lessons_count'
export const BOOKING_SATISFACTION_COMPLETED = 'booking_satisfaction_completed'
export const COURSE_PREMIUM_LESSONS_CONF = 'course_premium_lessons_conf'
export const TESTS_SATISFACTION_COMPLETED = 'tests_satisfaction_completed'
export const BOOK_READER_SETTINGS = 'book_reader_settings'
export const ACCESSIBILITY = 'accessibility'
export const IS_SEEN_GROUPS_TAB = 'is_seen_groups_tab'
export const RATING_HISTORY = 'rating_history'
export const SEVEN_DAY_CHALLENGE_POPUP_SHOW_DATA =
  'seven_day_challenge_popup_show_data'
export const SEVEN_DAY_CHALLENGE_IS_COMMITTED = '7day_challenge_is_committed'
export const CONSTRUCTOR_SPACE_KEYBOARD_HINT_WAS_SHOWN =
  'constructor_space_keyboard_hint_was_shown'
export const LESSONS_ONBOARDING_KEEPER_KEY = 'lessons-onboarding'
export const USER_SETTINGS = 'user_settings'
export const SPLIT_TEST_INTRO_LESSON = 'split_test_intro_lesson'
export const COMPLETED_INTRO_LESSON = 'completed_intro_lesson'
export const COMPLETE_FIRST_LESSON = 'completed_first_lesson'
export const CHANGE_LEVEL_POPUP_WAS_SHOWN = 'change_level_popup_was_shown'
export const MY_WORDS_FEATURE_WAS_SHOWN = 'my_words_feature_was_shown'
export const VIDEO_LESSON_INTRO_WAS_SHOWN = 'video_lesson_intro_was_shown'
export const WHITE_NOISE_PAUSE_TOAST_WAS_SHOWN =
  'white_noise_pause_toast_was_shown'
