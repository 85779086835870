import { createSlice } from '@reduxjs/toolkit'
import reducers, { initialState } from '@reduxStore/reducers/quizz/quizzReducer'

export const quizzSlice = createSlice({
  name: 'quizz',
  initialState,
  reducers,
})

export const { setFlowId } = quizzSlice.actions

export default quizzSlice.reducer
