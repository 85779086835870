import { useGrowthBook } from '@growthbook/growthbook-react'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useContext, useState } from 'react'

import {
  KEEPER_QUERY_KEY,
  addDataToProfile,
  useKeeperValue,
} from '@api/profile'

import { GROWTH_BOOK_DEFAULT_VALUE } from '@constants'

import { AuthContext } from '@modules/Auth/AuthProvider'

export const EXPERIMENTS_INVOLVED = 'ff_experiments_involved'
const MINUTES_5 = 1000 * 60 * 5

const useUpdateGrowthBookKeeper = (): ((splitData: {
  key?: string
  payload: { key: string; value: string }
}) => void) => {
  const { globalUser } = useContext(AuthContext)
  const growthBook = useGrowthBook()
  const [isSent, setIsSent] = useState(false)
  const queryClient = useQueryClient()

  const { data: experimentsData, isLoading: isExperimentsLoading } =
    useKeeperValue({
      params: { path: { key: EXPERIMENTS_INVOLVED } },
      userId: globalUser?.uid || '',
      reactQuery: {
        enabled: !!globalUser,
        staleTime: MINUTES_5,
        placeholderData: { value: '' },
      },
    })

  return useCallback(
    async (splitData: {
      key?: string
      payload: { key: string; value: string }
    }) => {
      const token = await globalUser?.getIdToken()
      if (
        !token ||
        splitData.payload.value === GROWTH_BOOK_DEFAULT_VALUE ||
        isSent
      ) {
        return
      }

      if (isExperimentsLoading) {
        return
      }

      // we expect here a string with comma separated values. like 'value1,value2,value3'
      // we agreed this format across platforms
      const previousArrayOfExperiments =
        experimentsData?.value?.split(',') || []
      const alreadyInExperiment = previousArrayOfExperiments.includes(
        splitData?.payload?.key
      )
      const nextArrayOfExperiments = alreadyInExperiment
        ? previousArrayOfExperiments
        : previousArrayOfExperiments.concat(splitData.payload.key)

      await growthBook.updateAttributes({
        ...growthBook.getAttributes(),
        [EXPERIMENTS_INVOLVED]: nextArrayOfExperiments,
      })

      const payload = nextArrayOfExperiments.join(',')

      if (!alreadyInExperiment) {
        const data = {
          key: EXPERIMENTS_INVOLVED,
          payload,
        }
        addDataToProfile(token, data).then(() => {
          setIsSent(true)
          queryClient.invalidateQueries({
            queryKey: [
              KEEPER_QUERY_KEY,
              EXPERIMENTS_INVOLVED,
              globalUser?.uid || '',
            ],
          })
        })
      } else {
        setIsSent(true)
      }
    },
    [
      experimentsData?.value,
      isExperimentsLoading,
      globalUser,
      growthBook,
      isSent,
      queryClient,
    ]
  )
}

export default useUpdateGrowthBookKeeper
