import { coursesHttpClient } from '@api/OpenApiClient'
import { paths } from '@api/schema/schema-courses'

import { UseQueryOptions } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

const DAILY_PLAN_PROGRESS_QUERY_KEY = '/v1/users/lessons/dailyplan/progress'

type DailyPlanProgress =
  paths[typeof DAILY_PLAN_PROGRESS_QUERY_KEY]['get']['responses']['200']['content']['application/json']

const getDailyPlanProgress = async () => {
  const { data } = await coursesHttpClient.get(DAILY_PLAN_PROGRESS_QUERY_KEY)
  return data
}

const useDailyPlanProgress = (
  config?: UseQueryOptions<
    paths[typeof DAILY_PLAN_PROGRESS_QUERY_KEY]['get'],
    Awaited<ReturnType<typeof getDailyPlanProgress>>,
    unknown,
    string
  >
) =>
  useQueryFactory([DAILY_PLAN_PROGRESS_QUERY_KEY], getDailyPlanProgress)(config)

export { getDailyPlanProgress, useDailyPlanProgress, type DailyPlanProgress }
