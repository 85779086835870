import { AxiosResponse } from 'axios'

import axiosInstance from '@api/axiosInstanse'

import { USER_TIMEZONE } from '@constants'

import { Challenge } from '@_types/courses'

const API_HOST = process.env.NEXT_PUBLIC_API_COURSES_HOST

export const getChallenge = async (params: {
  token: string
  challenge: string
}): Promise<AxiosResponse<Challenge>> => {
  const { token, challenge } = params

  return axiosInstance.get(`${API_HOST}/v1/challenge/${challenge}/progress`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-time-location': USER_TIMEZONE,
    },
  })
}
