export enum TwitterEventNames {
  PURCHASE = 'tw-ohhff-ohjkb',
  START_CHECKOUT = 'tw-ohhff-ohjke',
  SIGN_UP = 'tw-ohhff-ohjkc',
  PAGE_VIEW = 'tw-ohhff-ohhfp',
}

export enum SnapChatEventNames {
  PURCHASE = 'PURCHASE',
  START_CHECKOUT = 'START_CHECKOUT',
  SIGN_UP = 'SIGN_UP',
  PAGE_VIEW = 'PAGE_VIEW',
}

export enum PinterestEventNames {
  ADD_CARD = 'addtocart',
  CHECKOUT = 'checkout',
  SIGN_UP = 'signup',
  PAGE_VIEW = 'pagevisit',
}
