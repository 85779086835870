const coursesOnboardingAnswers = {
  target_language: {
    questionId: 'target_language',
    questionText: 'I want to learn:',
    category: 'survey',
    selectedAnswers: ['en'],
  },
  source_language: {
    questionId: 'source_language',
    questionText: 'Teaching will be in...',
    category: 'survey',
    selectedAnswers: ['en'],
  },
  motivation: {
    questionId: 'motivation',
    questionText: 'What is your motivation to learn target_language?',
    category: 'survey',
    selectedAnswers: [
      'career_job',
      'school',
      'culture',
      'brain_training',
      'family_friends',
      'travel',
      'other',
    ],
  },
  user_language_level: {
    questionId: 'user_language_level',
    questionText: 'Your current target_language level?',
    category: 'survey',
    selectedAnswers: ['C1'],
  },
  aspects_to_improve: {
    questionId: 'aspects_to_improve',
    questionText: 'What aspects of target_language would you like to improve?',
    category: 'survey',
    selectedAnswers: [
      'spelling',
      'vocabulary',
      'tenses',
      'punctuation',
      'phrasal_verbs',
      'language_styles',
    ],
  },
  how_much_time: {
    questionId: 'how_much_time',
    questionText:
      'How much time are you ready to spend daily on studying target_language?',
    category: 'survey',
    selectedAnswers: ['10_15_min'],
  },
  how_often_study: {
    questionId: 'how_often_study',
    questionText: 'How often would you like to study?',
    selectedAnswers: ['one_per_week'],
  },
}

export default coursesOnboardingAnswers
