import { ar, de, es, fr, it, pl, pt, tr, uk } from 'date-fns/locale'

// eslint-disable-next-line import/prefer-default-export
export const dictionariesDateFNS: Record<string, any> = {
  uk,
  es,
  de,
  it,
  tr,
  pt,
  ar,
  fr,
  pl,
}
