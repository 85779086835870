const tutoringShortOnboardingAnswers = {
  user_language_level: {
    questionId: 'user_language_level',
    questionText: 'What is your current English level?',
    category: 'survey',
    selectedAnswers: ['A1'],
  },
  motivation: {
    questionId: 'motivation',
    questionText: 'What are your main goals for learning English?',
    category: 'survey',
    selectedAnswers: ['career_job'],
  },
  user_age: {
    questionId: 'user_age',
    questionText: 'How old are you?',
    category: 'survey',
    selectedAnswers: ['Prefer not to say'],
  },
  learning_frequency: {
    questionId: 'learning_frequency',
    questionText: 'How often do you want to meet with your tutor?',
    category: 'survey',
    selectedAnswers: ['once'],
  },
  practicing_time: {
    questionId: 'practicing_time',
    questionText:
      'How much time per day do you want to spend practicing English?',
    category: 'survey',
    selectedAnswers: ['15-30 minutes'],
  },
}

export default tutoringShortOnboardingAnswers
