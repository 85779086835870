import { createSlice } from '@reduxjs/toolkit'

import extraReducers from '@reduxStore/reducers/challenges/challengesExtraReducers'

import reducers, { initialState } from './challengesReducers'

export const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers,
  extraReducers,
})

export const { setSevenDayChallenge, setSevenDayChallengeExpired } =
  challengesSlice.actions

export default challengesSlice.reducer
