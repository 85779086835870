import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { CourseActions, CoursesState } from '@reduxStore/reducers/courses/types'

import { ALPHABET } from '@constants'

export const initialState: CoursesState = {
  coursesList: [],
  userCourses: [],
  course: undefined,
  courseProgress: undefined,
  dailyPlan: undefined,
  dailyPlanProgress: undefined,
  dailyPlanActiveDay: 0,
  activeLevel: {
    value: '',
    scroll: false,
  },
  activeTopic: {
    value: '',
    scroll: false,
  },
  lesson: null,
  lessonAnalyticsIsSet: false,
  lessonAnalytics: {
    lesson: 'no_info',
    lesson_type: 'no_info',
    course: 'no_info',
    level: 'no_info',
    quiz_count: 'no_info',
    step_count: 'no_info',
    userLevel: 'no_info',
    where: 'no_info',
  },
  lessonMistakes: 0,
  letters: [...ALPHABET],
  loading: false,
  loadingError: false,
  progressError: false,
}

const reducers: SliceCaseReducers<CoursesState> = {
  [CourseActions.RESET_COURSES]: () => ({
    ...initialState,
  }),
  [CourseActions.SET_LESSON_LETTERS]: (
    state: CoursesState,
    action: PayloadAction<CoursesState['letters']>
  ) => ({
    ...state,
    letters: action.payload,
  }),
  [CourseActions.SET_COURSES_LIST]: (
    state: CoursesState,
    action: PayloadAction<Pick<CoursesState, 'coursesList' | 'userCourses'>>
  ) => ({
    ...state,
    ...action.payload,
  }),
  [CourseActions.SET_COURSE]: (
    state: CoursesState,
    action: PayloadAction<CoursesState['course']>
  ) => ({
    ...state,
    course: action.payload,
  }),
  [CourseActions.SET_ACTIVE_LEVEL]: (
    state: CoursesState,
    action: PayloadAction<CoursesState['activeLevel']>
  ) => ({
    ...state,
    activeLevel: action.payload,
  }),
  [CourseActions.SET_ACTIVE_TOPIC]: (
    state: CoursesState,
    action: PayloadAction<CoursesState['activeTopic']>
  ) => ({
    ...state,
    activeTopic: action.payload,
  }),
  [CourseActions.SET_LESSON]: (
    state: CoursesState,
    action: PayloadAction<CoursesState['lesson']>
  ) => ({
    ...state,
    lesson: action.payload,
  }),
  [CourseActions.SET_LESSON_ANALYTICS_DATA]: (
    state: CoursesState,
    action: PayloadAction<Partial<CoursesState['lessonAnalytics']>>
  ) => ({
    ...state,
    lessonAnalyticsIsSet: true,
    lessonAnalytics: {
      ...state.lessonAnalytics,
      ...action.payload,
    },
  }),
  [CourseActions.RESET_LESSON_ANALYTICS_DATA]: (state: CoursesState) => ({
    ...state,
    lessonAnalyticsIsSet: false,
  }),
  [CourseActions.ADD_LESSON_MISTAKE]: (state: CoursesState) => ({
    ...state,
    lessonMistakes: state.lessonMistakes + 1,
  }),
  [CourseActions.RESET_LESSON_MISTAKES]: (state: CoursesState) => ({
    ...state,
    lessonMistakes: 0,
  }),
  [CourseActions.SET_DAILY_PLAN_ACTIVE_DAY]: (
    state: CoursesState,
    action: PayloadAction<Partial<CoursesState['dailyPlanActiveDay']>>
  ) => ({
    ...state,
    dailyPlanActiveDay: action.payload,
  }),
}

export default reducers
