import { FC } from 'react'

import Appstore from '@promova/ui/icons/app_markets/appstore.svg'
import AppstoreSmall from '@promova/ui/icons/app_markets/appstore_small.svg'
import GooglePlay from '@promova/ui/icons/app_markets/google_play.svg'
import GooglePlaySmall from '@promova/ui/icons/app_markets/google_play_small.svg'
import PlayGallery from '@promova/ui/icons/app_markets/huawei.svg'
import PlayGallerySmall from '@promova/ui/icons/app_markets/huawei_small.svg'

export const storeLinks = {
  apple: 'https://promova.onelink.me/KnXG/csv61ky6',
  google: 'https://promova.onelink.me/KnXG/q3ealo7l',
  huawei: 'https://promova.onelink.me/KnXG/uylwxsaw',
  universal: 'https://promova.onelink.me/KnXG/izvibfdp',
}

export const DOWNLOAD_LINKS: {
  id: string
  link: string
  Icon: FC
  IconSmall: FC
}[] = [
  {
    id: 'app_store',
    link: storeLinks.universal,
    Icon: Appstore,
    IconSmall: AppstoreSmall,
  },
  {
    id: 'google_store',
    link: 'https://promova.onelink.me/KnXG/d1yd442p',
    Icon: GooglePlay,
    IconSmall: GooglePlaySmall,
  },
  {
    id: 'app_gallery',
    link: 'https://promova.onelink.me/KnXG/hyqa28rg',
    Icon: PlayGallery,
    IconSmall: PlayGallerySmall,
  },
]
