import { createSlice } from '@reduxjs/toolkit'

import extraReducers from './coursesExtraReducers'
import reducers, { initialState } from './coursesReducer'

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers,
  extraReducers,
})

export const {
  setCoursesList,
  setCourse,
  setLesson,
  resetCourses,
  setLessonAnalyticsData,
  resetLessonAnalyticsData,
  resetLessonMistakes,
  addLessonMistake,
  setLessonLetters,
  setActiveLevel,
  setActiveTopic,
  setDailyPlanActiveDay,
} = coursesSlice.actions

export default coursesSlice.reducer
