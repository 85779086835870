import coursesOnboardingAnswers from '@mockData/coursesOnboarding'
import tutoringShortOnboardingAnswers from '@mockData/tutoringShortOnboarding'
import coursesOnboarding from '@quizes/courses_onboarding'
import defaultQuiz from '@quizes/default_quiz'
import shortOnboarding from '@quizes/short_onboarding'

import createProxyWithDefaultValue from '@utils/createProxyWithDefaultValue'

import {
  USER_ANSWERS,
  USER_COURSES_ONBOARDING_ANSWERS,
  USER_LESSONS_ONBOARDING_ANSWERS,
  USER_SHORT_ONBOARDING_ANSWERS,
} from './common'
import {
  COURSES_ONBOARDING,
  LESSONS_ONBOARDING,
  SHORT_ONBOARDING,
} from './flow_ids'

const answersIdByFlow = {
  [SHORT_ONBOARDING]: USER_SHORT_ONBOARDING_ANSWERS,
  [COURSES_ONBOARDING]: USER_COURSES_ONBOARDING_ANSWERS,
  [LESSONS_ONBOARDING]: USER_LESSONS_ONBOARDING_ANSWERS,
}

export const SAVED_ANSWERS_ID_BY_FLOW_ID = createProxyWithDefaultValue<string>(
  answersIdByFlow,
  USER_ANSWERS // equivalent [DEFAULT_FLOW_ID]:USER_ANSWERS
)

const quizzesByFlowId = {
  [SHORT_ONBOARDING]: shortOnboarding,
  [COURSES_ONBOARDING]: coursesOnboarding,
}

export const QUIZZES_BY_FLOW_ID = createProxyWithDefaultValue<
  Record<string, any>
>(quizzesByFlowId, defaultQuiz)

const mockedAnswersByFlowId = {
  [COURSES_ONBOARDING]: coursesOnboardingAnswers,
  [SHORT_ONBOARDING]: tutoringShortOnboardingAnswers,
}

export const MOCKED_ANSWERS_BY_FLOW_ID = createProxyWithDefaultValue<
  Record<string, any>
>(mockedAnswersByFlowId, coursesOnboardingAnswers)
