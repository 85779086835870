import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import styles from './topics.module.scss'

type CardCategory =
  | 'Business English'
  | 'English with TV series'
  | 'Travel English'
  | 'General English'

const categories: Array<CardCategory> = [
  'Business English',
  'English with TV series',
  'Travel English',
  'General English',
]

type Card = {
  id: string
  category: CardCategory
  topic: CardCategory
  image: string
  word: string
  description: string
}
const cards: Array<Card> = [
  {
    id: 'business promotion',
    category: 'Business English',
    topic: 'Business English',
    image: 'card_0.svg',
    word: 'promotion',
    description: 'a move to higher position',
  },
  {
    id: 'business join forces',
    category: 'Business English',
    topic: 'Business English',
    image: 'card_1.svg',
    word: 'join forces',
    description: 'to work together',
  },
  {
    id: 'business leave without pay',
    category: 'Business English',
    topic: 'Business English',
    image: 'card_2.svg',
    word: 'leave without pay',
    description: 'time away from work without any compensation',
  },
  {
    id: 'tv drift apart',
    category: 'English with TV series',
    topic: 'English with TV series',
    image: 'card_3.svg',
    word: 'drift apart',
    description: 'stop being friends',
  },
  {
    id: 'tv consensual',
    category: 'English with TV series',
    topic: 'English with TV series',
    image: 'card_4.svg',
    word: 'consensual',
    description: 'agreed with all the people involved',
  },
  {
    id: 'tv ridiculous',
    category: 'English with TV series',
    topic: 'English with TV series',
    image: 'card_5.svg',
    word: 'ridiculous',
    description: 'so silly that makes people laugh',
  },
  {
    id: 'travel double-decker',
    category: 'Travel English',
    topic: 'Travel English',
    image: 'card_6.svg',
    word: 'double-decker',
    description: 'a bus with two levels',
  },
  {
    id: 'travel train crew',
    category: 'Travel English',
    topic: 'Travel English',
    image: 'card_7.svg',
    word: 'train crew',
    description: 'people who work on a train',
  },
  {
    id: 'travel pet-friendly',
    category: 'Travel English',
    topic: 'Travel English',
    image: 'card_8.svg',
    word: 'pet-friendly',
    description: 'allowing you to stay with your pet',
  },
  {
    id: 'general once in a blue moon',
    category: 'General English',
    topic: 'General English',
    image: 'card_9.jpg',
    word: 'once in a blue moon',
    description: 'very rarely',
  },
  {
    id: 'general point at',
    category: 'General English',
    topic: 'General English',
    image: 'card_10.svg',
    word: 'point at',
    description: 'to direct your index finger at someone or something',
  },
  {
    id: 'general disparity',
    category: 'General English',
    topic: 'General English',
    image: 'card_11.jpg',
    word: 'disparity',
    description: 'a great difference between two things',
  },
]

const Topics = () => {
  const [currentCardId, setCurrentCardId] = useState(cards[0].id)
  const [isArrowClicked, setIsArrowClicked] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [currentTopic, setCurrentTopic] = useState('Business English')
  const [currentCategory, setCurrentCategory] =
    useState<CardCategory>('Business English')

  // control cards by tabs
  useEffect(() => {
    const currentCardsFromCategory = cards.filter(
      (card) => card.category === currentCategory
    )

    if (!isArrowClicked) {
      const firstCardId = currentCardsFromCategory[0].id

      setCurrentCardId(firstCardId)
    }
  }, [currentCategory, isArrowClicked])

  useEffect(() => {
    const currentCard = cards.find((item) => item.id === currentCardId)

    if (currentCard) {
      const { topic, category } = currentCard
      setCurrentCategory(category)
      setCurrentTopic(topic)
    }
  }, [currentCardId])

  const handleSwitchBetweenCards = (type: 'next' | 'prev'): void => {
    setIsArrowClicked(true)

    const currentCardIndex = cards.findIndex(
      (item) => item.id === currentCardId
    )

    let newCardIndex =
      type === 'next' ? currentCardIndex + 1 : currentCardIndex - 1

    if (currentCardIndex === 0 && type === 'prev') {
      newCardIndex = cards.length - 1
    }

    if (currentCardIndex === cards.length - 1 && type === 'next') {
      newCardIndex = 0
    }

    const newCardId = cards[newCardIndex].id

    setCurrentCardId(newCardId)
  }

  const handleChangeCategory = (category: CardCategory): void => {
    if (category === currentCategory) return

    const topic = cards.find((item) => item.category === category)?.topic

    setIsArrowClicked(false)

    setCurrentCategory(category)

    if (topic) {
      setCurrentTopic(topic)
    }
  }

  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState)

  const arrowClassNames = classNames(styles.arrow_down, {
    [styles.arrow_up]: isDropdownOpen,
  })

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <Trans>Try different ways to&nbsp;improve your English</Trans>
      </p>

      <button
        type="button"
        className={styles.dropdown}
        onClick={toggleDropdown}
      >
        <p>{currentTopic}</p>
        <div className={arrowClassNames} />
        {isDropdownOpen && (
          <div className={styles.dropdown_list}>
            {categories.map((category) => (
              <button
                key={category}
                type="button"
                className={styles.dropdown_item}
                onClick={handleChangeCategory.bind(this, category)}
              >
                {category}
              </button>
            ))}
          </div>
        )}
      </button>

      <div className={styles.slider_wrapper}>
        <button
          type="button"
          onClick={() => handleSwitchBetweenCards('prev')}
          className={styles.arrow_button}
        >
          <div className={styles.arrow_left} />
        </button>

        {cards.map(({ id, word, image, description, topic }) =>
          id === currentCardId ? (
            <div key={id} className={styles.card}>
              <p className={styles.topic}>{topic}</p>
              <div className={styles.image_wrapper}>
                <img
                  src={`/images/courses-onboarding/topics/${image}`}
                  alt={word}
                  className={styles.image}
                />
              </div>
              <div className={styles.text_wrapper}>
                <p className={styles.word}>{word}</p>
                <p className={styles.description}>{description}</p>
              </div>
            </div>
          ) : null
        )}

        <button
          type="button"
          onClick={() => handleSwitchBetweenCards('next')}
          className={styles.arrow_button}
        >
          <div className={styles.arrow_right} />
        </button>
      </div>
    </div>
  )
}

export default Topics
