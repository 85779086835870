const createProxyWithDefaultValue = <T>(
  obj: Record<string, any>,
  defaultReturnedValue: T
) =>
  new Proxy(obj, {
    get: (target: Record<string, any>, prop: string) =>
      prop in target ? target[prop] : defaultReturnedValue,
  })

export default createProxyWithDefaultValue
