import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'

import useRouterLocales from '@promova/utils/customHooks/useRouterLocales'

import { ROUTES_DISABLE_LOCALES } from '@constants'

interface Props {
  availableLocales?: string[]
}

const CommonHead: FC<Props> = ({ availableLocales }) => {
  const router = useRouter()
  const locales = useRouterLocales()

  const isShowLocales =
    !ROUTES_DISABLE_LOCALES.includes(router?.route) && !availableLocales?.length

  const domain = process.env.NEXT_PUBLIC_LIVE_MODE
    ? 'promova.com'
    : process.env.NEXT_PUBLIC_VERCEL_URL

  const path = router?.asPath !== '/' ? router?.asPath : ''
  return (
    <Head>
      {/* favicons start */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link
        rel="mask-icon"
        href="/favicons/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      {/* eslint-disable-next-line react/no-invalid-html-attribute */}
      <link rel="shortcut icon" href="/favicons/favicon.ico" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      <meta name="theme-color" content="#000000" />
      <link rel="manifest" href="/manifest.json" />
      {/* favicons end */}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500;700&display=swap"
        rel="stylesheet"
      />
      {isShowLocales &&
        locales?.map((locale) => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={`https://${
              process.env.NEXT_PUBLIC_LIVE_MODE
                ? 'promova.com'
                : process.env.NEXT_PUBLIC_VERCEL_URL
            }${locale !== router.defaultLocale ? `/${locale}` : ''}${
              router?.asPath !== '/' ? router?.asPath : ''
            }`}
          />
        ))}

      {!!availableLocales?.length &&
        availableLocales?.map((locale) => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={`https://${domain}${
              locale !== router.defaultLocale ? `/${locale}` : ''
            }${path}`}
          />
        ))}

      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://${domain}${path}`}
      />
      <title>Promova</title>
      <meta name="description" content="Best tool to learn languages" />
    </Head>
  )
}

export default CommonHead
