import { ProcessedAnswer } from '@_types/results_page'
import { Trans } from '@lingui/macro'

type AnswersMapList = Record<string, ProcessedAnswer>

export const USER_LEVEL_ANSWERS_MAP: AnswersMapList = {
  A1: { id: 'A1', text: <Trans>Beginner</Trans>, icon: '👶🏻' },
  A2: { id: 'A2', text: <Trans>Elementary</Trans>, icon: '🙋🏻‍♂️' },
  B1: { id: 'B1', text: <Trans>Intermediate</Trans>, icon: '😎' },
  C1: { id: 'C1', text: <Trans>Advanced</Trans>, icon: '💪🏻' },
  C2: { id: 'C2', text: <Trans>Proficient</Trans>, icon: '👨🏻‍🎓' },
}

export const MOTIVATION_ANSWERS_MAP: AnswersMapList = {
  career_job: {
    id: 'career_job',
    text: <Trans>Career, job opportunities</Trans>,
    icon: '💼',
  },
  school: { id: 'school', text: <Trans>Education</Trans>, icon: '🎓' },
  culture: { id: 'culture', text: <Trans>Culture</Trans>, icon: '🗽' },
  brain_training: {
    id: 'brain_training',
    text: <Trans>Brain training</Trans>,
    icon: '🧠',
  },
  family_friends: {
    id: 'family_friends',
    text: <Trans>Family & Friends</Trans>,
    icon: '👪',
  },
  travel: { id: 'travel', text: <Trans>Travel</Trans>, icon: '✈️' },
  other: { id: 'other', text: <Trans>Other</Trans>, icon: '🙄' },
}

export const ASPECTS_TO_IMPROVE_ANSWERS_MAP: AnswersMapList = {
  spelling: { id: 'spelling', text: <Trans>Spelling</Trans>, icon: '🗣️' },
  vocabulary: { id: 'vocabulary', text: <Trans>Vocabulary</Trans>, icon: '📖' },
  punctuation: {
    id: 'punctuation',
    text: <Trans>Punctuation</Trans>,
    icon: '🖊️',
  },
  tenses: { id: 'tenses', text: <Trans>Tenses</Trans>, icon: '⏳' },
  phrasal_verbs: {
    id: 'phrasal_verbs',
    text: <Trans>Phrasal verbs</Trans>,
    icon: '✨',
  },
  language_styles: {
    id: 'language_styles',
    text: <Trans>Language styles</Trans>,
    icon: '👔',
  },
}
