import { AxiosError } from 'axios'

import { setNoConnection } from '@features/NoConnectionAlert/networkErrorSlice'

let timeout: NodeJS.Timeout
const TIMEOUT = 10000 // 10 seconds

export const noConnectionInterceptor = (err: AxiosError) => {
  if (typeof window === 'undefined') {
    return
  }

  if (err?.response?.status === 404) {
    throw err
  }

  const store = (window as any)._store

  if (err?.code === 'ERR_NETWORK' && typeof store?.dispatch === 'function') {
    clearTimeout(timeout)
    store.dispatch(setNoConnection(true))
    timeout = setTimeout(() => {
      store.dispatch(setNoConnection(false))
    }, TIMEOUT)
  }

  throw err
}
