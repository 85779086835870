import { createSlice } from '@reduxjs/toolkit'

import reducers from './commonReducer'
import { CommonState } from './types'

const initialState: CommonState = {
  isInitializeFacebook: false,
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers,
})

export const { setIsInitializeFacebook } = commonSlice.actions
export default commonSlice.reducer
