import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { get7DayChallenge } from '@reduxStore/reducers/challenges/challengesThunks'
import { ChallengesState } from '@reduxStore/reducers/challenges/types'

import {
  ActivitiesTimeUnits,
  ActivitiesTypes,
  Challenge,
  ChallengeStatuses,
} from '@_types/courses'

const sevenDayChallengeMock: Challenge = {
  status: ChallengeStatuses.NEW,
  created_at: new Date().toISOString(),
  activities: new Array(7).fill(0).map((_, index) => ({
    step: index + 1,
    time_unit: ActivitiesTimeUnits.DAY,
    name: '7day_challenge',
    status: ChallengeStatuses.NEW,
    created_at: new Date().toISOString(),
    type: ActivitiesTypes.ACTIVATE,
  })),
}

const extraReducers = (
  builder: ActionReducerMapBuilder<ChallengesState>
): void => {
  builder
    .addCase(get7DayChallenge.fulfilled, (state, action) => ({
      ...state,
      sevenDayChallenge: action.payload,
    }))
    .addCase(get7DayChallenge.rejected, (state) => ({
      ...state,
      sevenDayChallenge: sevenDayChallengeMock,
    }))
}

export default extraReducers
