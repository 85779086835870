import axios from 'axios'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

interface ResultCountry {
  country: string
  countryCode: string
  region: string
  timezone: string
}

const placeholder = {
  country: '',
  countryCode: '',
  region: '',
  timezone: '',
}

export const getCountry = async (): Promise<ResultCountry> => {
  const data = await axios.get(
    `https://pro.ip-api.com/json?key=${process.env.NEXT_PUBLIC_IP_API_KEY}`
  )
  return data?.data
}

export const useQueryCountry = (): ResultCountry => {
  const { data } = useQueryFactory<ResultCountry>(
    ['country'],
    getCountry
  )({
    cacheTime: Infinity,
    staleTime: Infinity,
    placeholderData: placeholder,
  })

  return data || placeholder
}
