// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NEXT_PUBLIC_LIVE_MODE === 'true') {
  Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'Network Error',
      'TypeError: undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
      'undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
      'Request failed with status code 404',
      "Cannot read properties of undefined (reading 'site_id')",
      "Unexpected token '<'",
      'Load failed',
      "Cannot read properties of undefined (reading 'domInteractive')",
      'UnknownError: Database deleted by request of the user',
      "undefined is not an object (evaluating '__gCrWeb.instantSearch.setIOSParameters')",
      "Can't find variable: _AutofillCallbackHandler",
      "undefined is not an object (evaluating 'a.L')", // can't reproduce
    ],
    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null

      if (hint?.originalException?.name === 'AxiosError') {
        if (hint?.originalException?.response?.headers) {
          const contexts = {
            ...event.contexts,
          }

          contexts.errorResponse = {
            headers: hint.originalException.response.headers,
          }

          // eslint-disable-next-line no-param-reassign
          event.contexts = contexts
        }
      }

      return event
    },
  })
}
