import { i18n } from '@lingui/core'

async function loadCatalog(locale?: string) {
  const { messages } = await import(`../locales/${locale}/file`)

  i18n.load(locale as string, messages)
  i18n.activate(locale as string)

  return messages
}

export default loadCatalog
