import { AxiosResponse } from 'axios'

import axiosInstance from '@api/axiosInstanse'

import { Product } from '@_types/subscription'

const API_HOST = process.env.NEXT_PUBLIC_API_HOST

interface UpsaleConfig {
  testResultId: string
  orderId: string
  planId: string
  purchaseUrl: string
  products: Array<{ product_id: string }>
}

export const upsaleRequest = async (
  props: UpsaleConfig
): Promise<Record<string, any> | null> => {
  if (!props) return null

  const { orderId, planId, purchaseUrl, products, testResultId } = props
  const body = {
    billing_plan_id: planId,
    order_id: orderId,
    quiz_result_id: testResultId,
    purchase_url: purchaseUrl,
    products,
  }

  const data = await axiosInstance.post(
    `${API_HOST}/v1/billing/upsales`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return data?.data
}

export const getSubscriptions = async (
  token: string
): Promise<Record<string, any> | null> => {
  const data = await axiosInstance.get(`${API_HOST}/v1/billing/subscriptions`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return data?.data
}

export const getCredits = async (
  token: string
): Promise<Record<string, any> | null> => {
  const data = await axiosInstance.get(`${API_HOST}/v1/billing/credits`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return data?.data
}

export interface CreditsData {
  total_amount: number
  max_expiration_date: number
  regular_amount?: number
  trial_amount?: number
  one_time_amount?: number
  regular_max_expiration_date?: number
  trial_max_expiration_date?: number
  one_time_max_expiration_date?: number
}

export interface CreditsResponse {
  individual: CreditsData
  group: CreditsData
  tutor_subscription_status: string
}

export const getCreditsV2 = async (
  token: string
): Promise<AxiosResponse<CreditsResponse>> => {
  const res = await axiosInstance.get(`${API_HOST}/v2/billing/credits`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}

export const getCreditsHistory = async (
  token: string,
  params?: Record<string, any>
): Promise<Record<string, any> | null> => {
  const data = await axiosInstance.get(
    `${API_HOST}/v1/billing/credits/history`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params,
    }
  )

  return data?.data
}

export const getActiveSubscriptions = (
  token: string
): Promise<AxiosResponse<Record<string, any>>> =>
  axiosInstance.get(`${API_HOST}/v1/billing/subscriptions`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

export const getAvailableProducts = (
  token: string,
  filter?: Record<string, string[]>
): Promise<AxiosResponse<Product[]>> => {
  const params = new URLSearchParams()
  if (filter) {
    Object.keys(filter).forEach((key) => {
      filter[key].forEach((value) => params.append(key, value))
    })
  }

  return axiosInstance.get(`${API_HOST}/v1/billing/products/available`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params,
  })
}

export const createCouponInPandaBillingProvider = async (props: {
  userId: string
  orderId: string
  couponCode: string
}): Promise<Record<string, any>> => {
  const { userId, orderId, couponCode } = props
  const body = {
    order_id: orderId,
    coupon_code: couponCode,
  }

  return axiosInstance.post(
    `${API_HOST}/v1/billing/coupon/users/${userId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export interface OneClickPayInfo {
  type: string
  order_id: string
  can_one_click_pay: boolean
}

export const getOneClickPayInfo = (
  token: string
): Promise<AxiosResponse<OneClickPayInfo>> =>
  axiosInstance.get(`${API_HOST}/v1/billing/one-click-pay`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

export const makeOneClickPayment = async (props: {
  billingPlanId: string
  orderId: string
  token: string
  purchaseUrl: string
}): Promise<Record<string, any>> => {
  const { billingPlanId, orderId, token, purchaseUrl } = props
  const body = {
    billing_plan_id: billingPlanId,
    order_id: orderId,
    purchase_url: purchaseUrl,
  }

  return axiosInstance.post(`${API_HOST}/v1/billing/one-click-pay`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
