import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

export type QueryOptions<
  T = unknown,
  E = unknown,
  S = T,
  Q extends QueryKey = QueryKey
> = Omit<UseQueryOptions<T, E, S, Q>, 'queryKey' | 'queryFn'>

export const useQueryFactory =
  <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
  >(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>
  ) =>
  <SelectType = TData>(
    options?: QueryOptions<TQueryFnData, TError, SelectType, TQueryKey>
  ) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery(queryKey, queryFn, options)

export const useMutationFactory =
  <
    TMutationFnData = unknown,
    TMutationError = unknown,
    TVariables = unknown,
    TContext = unknown
  >(
    mutationFn: MutationFunction<TMutationFnData, TVariables>
  ) =>
  (
    options?: UseMutationOptions<
      TMutationFnData,
      TMutationError,
      TVariables,
      TContext
    >
  ) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(mutationFn, options)
