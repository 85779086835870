// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp, getApp, FirebaseApp } from 'firebase/app'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: 'AIzaSyCjmKoKYNWRcwNJzx3FPvIIaGNrPtgbgKg',
  authDomain: 'ten-words.firebaseapp.com',
  databaseURL: 'https://ten-words.firebaseio.com',
  projectId: 'ten-words',
  storageBucket: 'ten-words.appspot.com',
  messagingSenderId: '331925000858',
  appId: '1:331925000858:web:10d7d373ea4bae78f44523',
  measurementId: 'G-1KVYMWH3X7',
}

// Initialize Firebase
const firebaseInit = (): FirebaseApp => {
  let app

  try {
    app = getApp()
  } catch (error) {
    app = initializeApp(config)
  }

  return app
}

export default firebaseInit
