import { defineMessage } from '@lingui/macro'

export const fillingOutPoint = {
  3: '1st show',
  6: '2nd show',
  9: '3rd show',
}

export const signInWay: Record<string, string> = {
  password: 'email',
  'google.com': 'social-google',
  'apple.com': 'social-apple-id',
}

export const languageLabelLevels = {
  A1: defineMessage({
    message: 'Beginner',
  }),
  A2: defineMessage({
    message: 'Pre-Intermediate',
  }),
  B1: defineMessage({
    message: 'Intermediate',
  }),
  B2: defineMessage({
    message: 'Upper Intermediate',
  }),
  C1: defineMessage({
    message: 'Advanced',
  }),
  C2: defineMessage({
    message: 'Proficient',
  }),
}
