import { t } from '@lingui/macro'

const defaultQuiz = {
  quiz_id: 'default_quiz_id',
  survey_type: 'common',
  language_test_type: 'common', // without this value we can't save quiz result in db
  mainQuestions: [
    {
      id: 'user_language_level',
      question: t`What is your current English level?`,
    },
    {
      id: 'motivation',
      question: t`What are your top goals for learning English?`,
    },
    {
      id: 'target_language',
      question: t`What are your top goals for learning English?`,
    },
    {
      id: 'learning_frequency',
      question: t`How often do you want to meet with your tutor?`,
    },
  ],
}

export default defaultQuiz
