import { Challenge } from '@_types/courses'

export type ChallengesState = {
  sevenDayChallenge: Challenge | null
  sevenDayChallengeExpired: boolean
}

export enum ChallengeActions {
  GET_SEVEN_DAY_CHALLENGE = 'challenges/getSevenDayChallenge',
  SET_SEVEN_DAY_CHALLENGE_EXPIRED = 'setSevenDayChallengeExpired',
}
