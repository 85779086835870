import { useEffect } from 'react'

import { useAppSelector } from '@reduxStore/hooks'

import { ACCESSIBILITY } from '@constants'

import {
  AccessibilitySettingsKeys,
  AccessibilitySettings as AccessibilitySettingsType,
} from '@_types/profile_keeper'

import useAddBodyClassName from '@hooks/useAddBodyClassName'
import { useKeeper } from '@hooks/useKeeper'

type Config = {
  enabled?: boolean
}

export const useTheme = (config?: Config) => {
  const { value: accessibilitySettings } = useKeeper<
    Partial<AccessibilitySettingsType>
  >({
    key: ACCESSIBILITY,
    defaultValue: {},
    enabled: config?.enabled,
  })

  const dyslexicMode = Boolean(
    accessibilitySettings?.[AccessibilitySettingsKeys.DYSLEXIC]
  )

  const isDyslexicModeAllowed = useAppSelector(
    (state) => state.theme.isDyslexicModeAllowed
  )

  useAddBodyClassName('body_font')

  useEffect(() => {
    if (!isDyslexicModeAllowed) {
      document.body.removeAttribute('data-dyslexic')
      return
    }

    document.body.setAttribute('data-dyslexic', `${dyslexicMode}`)
  }, [dyslexicMode, isDyslexicModeAllowed])
}
