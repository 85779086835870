import axios, { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import createClient, {
  FetchOptions,
  FetchResponse,
  Middleware,
  MiddlewareRequest,
} from 'openapi-fetch'
import {
  FilterKeys,
  HasRequiredKeys,
  PathsWithMethod,
} from 'openapi-typescript-helpers'

import { USER_TIMEZONE } from '@constants_folder/common'
import { CookieKeys } from '@constants_folder/storageKeys'

import { paths } from './schema'

type StsTokenManager = {
  accessToken?: string
  expirationTime?: number
  refreshToken?: string
}

type RefreshTokenResponse = {
  expires_in: string
  token_type: string
  refresh_token: string
  id_token: string
  user_id: string
  project_id: string
}

export type MaybeOptionalInit<P, M extends keyof P> = HasRequiredKeys<
  FetchOptions<FilterKeys<P, M>>
> extends never
  ? [(FetchOptions<FilterKeys<P, M>> | undefined)?]
  : [FetchOptions<FilterKeys<P, M>>]

const getToken = async () => {
  const stsTokenManager: StsTokenManager = JSON.parse(
    Cookies.get(CookieKeys.STS_TOKEN_MANAGER) || '{}'
  )

  if (!stsTokenManager?.accessToken || !stsTokenManager?.refreshToken) {
    return ''
  }

  const expirationTime = stsTokenManager.expirationTime || 0

  const dateNow = Date.now()

  if (expirationTime < dateNow) {
    const { data }: AxiosResponse<RefreshTokenResponse> = await axios.post(
      'https://securetoken.googleapis.com/v1/token?key=AIzaSyCjmKoKYNWRcwNJzx3FPvIIaGNrPtgbgKg', // TODO: move key to env variables
      {
        grant_type: 'refresh_token',
        refresh_token: stsTokenManager.refreshToken,
      }
    )

    const updatedStsTokenManager: StsTokenManager = {
      accessToken: data.id_token,
      expirationTime: dateNow + parseInt(data.expires_in, 10) * 1000,
      refreshToken: data.refresh_token,
    }

    Cookies.set(
      CookieKeys.STS_TOKEN_MANAGER,
      JSON.stringify(updatedStsTokenManager)
    )

    return updatedStsTokenManager.accessToken
  }

  return stsTokenManager.accessToken
}
const middlewareCallBack: Middleware = {
  onRequest: async (req: MiddlewareRequest) => {
    const token = await getToken()

    req.headers.set('Authorization', `Bearer ${token}`)
    req.headers.set('x-time-location', USER_TIMEZONE)

    return req
  },
}

class HttpClient {
  private instance

  constructor(
    middleware: Middleware,
    baseUrl: string = process.env.NEXT_PUBLIC_API_HOST || ''
  ) {
    this.instance = createClient<paths>({ baseUrl })
    this.instance.use(middleware)
  }

  async get<
    P extends PathsWithMethod<paths, 'get'>,
    I extends MaybeOptionalInit<paths[P], 'get'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['get'], I[0]>> {
    const result = await this.instance.GET(url, ...init)

    return result
  }

  async post<
    P extends PathsWithMethod<paths, 'post'>,
    I extends MaybeOptionalInit<paths[P], 'post'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['post'], I[0]>> {
    const result = await this.instance.POST(url, ...init)
    return result
  }

  async put<
    P extends PathsWithMethod<paths, 'put'>,
    I extends MaybeOptionalInit<paths[P], 'put'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['put'], I[0]>> {
    const result = await this.instance.PUT(url, ...init)
    return result
  }

  async patch<
    P extends PathsWithMethod<paths, 'patch'>,
    I extends MaybeOptionalInit<paths[P], 'patch'>
  >(url: P, ...init: I): Promise<FetchResponse<paths[P]['patch'], I[0]>> {
    const result = await this.instance.PATCH(url, ...init)
    return result
  }
}

const coursesHttpClient = new HttpClient(
  middlewareCallBack,
  process.env.NEXT_PUBLIC_API_COURSES_HOST
)

const tutorsHttpClient = new HttpClient(
  middlewareCallBack,
  process.env.NEXT_PUBLIC_API_TUTORS
)

const mainHttpClient = new HttpClient(
  middlewareCallBack,
  process.env.NEXT_PUBLIC_API_HOST
)

export { coursesHttpClient, mainHttpClient, tutorsHttpClient }

export default HttpClient
