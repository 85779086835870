import { Themes } from '@_types/index'

export interface ThemeState {
  specialUITheme: Themes
  isDyslexicModeAllowed: boolean
}

export enum ThemeActions {
  SET_SPECIAL_UI_THEME = 'setSpecialUITheme',
  TOGGLE_DYSLEXIC_MODE_ALLOWED = 'toggleDyslexicModeAllowed',
}
