export const DEFAULT_FLOW_ID = 'default_flow_id'
export const PURCHASE_FROM_ACCOUNT = 'purchase_from_account'
export const TUTORING_PRICING = 'tutoring-pricing'
export const SHORT_ONBOARDING = 'tutoring-short-onboarding'
export const INTERNAL_TUTORS = 'internal_tutors'
export const INTERNAL_SETTINGS = 'internal_settings'
export const COURSES_ONBOARDING = 'courses-onboarding'
export const LESSONS_ONBOARDING = 'lessons-onboarding'

export const DEFAULT_FLOW = SHORT_ONBOARDING

export const TUTORS_FLOW_IDS = [
  PURCHASE_FROM_ACCOUNT,
  TUTORING_PRICING,
  SHORT_ONBOARDING,
  INTERNAL_TUTORS,
  INTERNAL_SETTINGS,
  COURSES_ONBOARDING,
  LESSONS_ONBOARDING,
]

export const ALL_FLOW_IDS = [DEFAULT_FLOW_ID, ...TUTORS_FLOW_IDS]

// separate pdf
export const PHRASAL_VERBS_UPSALE = 'phrasal-verbs-upsale'
export const IRREGULAR_VERBS_UPSALE = 'irregular-verbs-upsale'
export const PDF_UPSALE = 'pdf-upsale'

// all pdf courses
export const UPSALES_DOWNSELL = 'ups-downsell'
