export enum LessonStatus {
  BOOKED = 'booked',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  MISSED = 'missed',
}

export interface Lesson {
  course: string
  created_at: number
  duration: number
  id: string
  location: string
  location_link: string
  start_time: number
  status: LessonStatus
  title: string
  tutor_id: string
  user_id: string
  tutor: Record<string, any>
}

export type LessonType = 'individual' | 'group'

export interface TutorLesson {
  course: string
  created_at: number
  duration: number
  id: string
  location: string
  location_link: string
  start_time: number
  status: LessonStatus
  title: string
  tutor_id: string
  user_id: string
  user: Record<string, any>
  book_again: Record<string, any>
  need_fill_feedback?: boolean
  type: LessonType
}

export interface Tutor {
  id: string
  avatar: string
  full_name: string
}

export interface Student {
  avatar: string
}

export enum GroupLessonStatus {
  OPEN_TO_JOIN = 'open_to_join',
  CLOSE_TO_JOIN = 'close_to_join',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export interface TutorGroupLesson {
  id: string
  start_time: number
  language: string
  unit_number: number
  lesson_number: number
  language_level: string
  title: string
  description: string
  duration: number
  credit_price: number
  status: GroupLessonStatus
  tags: string[]
  tutor: Tutor
  location: string
  location_link: string
  how_many_more_students_can_join: number
  max_students: number
  students: Student[]
  starts_in_seconds?: number
  type: LessonType
}
