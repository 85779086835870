import { AxiosResponse } from 'axios'

import axiosInstance from '@api/axiosInstanse'

const API_HOST = process.env.NEXT_PUBLIC_API_HOST

export const createUserByEmail = async (
  user: Record<string, any>
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.post(`${API_HOST}/v1/users`, user, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return {
    user: res?.data,
    status: res?.status,
  }
}

export const setUserEmail = async (
  token: string,
  userId: string,
  body: {
    email: string
    old_email?: string
  }
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.patch(
    `${API_HOST}/v1/users/${userId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res?.data
}

type SetFunnelPayloadBillingUser = {
  payload: {
    page_view: {
      url: string
    }
  }
  user_id: string
  sid: string
}

export const setFunnelPayloadBillingUser = async (
  data: SetFunnelPayloadBillingUser
) => {
  const res = await axiosInstance.post(
    `${API_HOST}/v1/billing/funnel/users/payload`,
    data
  )

  return res
}

// create user in Panda
export const setPayloadBillingUser = async (
  token: string,
  data?: Record<string, any>
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.post(
    `${API_HOST}/v1/billing/users/payload`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res?.data
}

export const getUser = async (
  userId: string
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/users/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return res?.data
}

export const saveUserFirebaseId = async (
  userId: string,
  token: string
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.patch(
    `${API_HOST}/v1/users/${userId}/register`,
    null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PATCH',
    }
  )

  return res?.data
}

export const getUserByFirebaseId = async (
  token: string
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/users/auth-id`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return {
    user: res?.data,
    status: res?.status,
  }
}

export const createUserByFirebaseId = async (
  token: string
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.post(`${API_HOST}/v1/users/auth`, null, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  })

  return res?.data
}

export const saveUserUtm = async (
  token: string,
  utms: Record<string, any>
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.post(`${API_HOST}/v1/users/utm`, utms, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res?.data
}

export const checkUserExists = (
  email: string
): Promise<AxiosResponse<{ auth_method: string[] }>> =>
  axiosInstance.post(
    `${API_HOST}/v1/users/check`,
    { email },
    { headers: { 'Content-Type': 'application/json' } }
  )

export const saveUserSplitValue = (
  token: string,
  splitVlaue: string
): Promise<AxiosResponse<Record<string, any>>> =>
  axiosInstance.post(
    `${API_HOST}/v1/users/split`,
    { split_value: splitVlaue },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )

interface UserParams {
  token: string
  params: {
    country: string // "Ukraine"
    time_location: string // "Europe/Kiev"
    current_time: string // "2021-12-01T12:00:00+02:00",
    current_ip: string // "245.224.101.201"
    locale: string // "uk-UA"
  }
}

export const markFreeUkraineUser = ({
  token,
  params,
}: UserParams): Promise<AxiosResponse<Record<string, any>>> =>
  axiosInstance.post(`${API_HOST}/v1/free_for_ukraine`, params, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

export const deleteUser = async (
  token: string
): Promise<AxiosResponse<Record<string, any>>> => {
  const res = await axiosInstance.delete(`${API_HOST}/v1/users/delete`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}

export const transferUserData = async (uid: string | null, token: string) => {
  if (uid) {
    try {
      await axiosInstance.post(
        `${API_HOST}/v1/users/transfer/${uid}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    } catch (err) {
      //
    }
  }
}

export const saveUserConsent = async (
  token: string,
  params: Record<string, any>
) => {
  const res = await axiosInstance.post(`${API_HOST}/v1/dnt`, params, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}

export const getAuthLink = async (
  token: string,
  params: Record<string, any>
) => {
  const res = await axiosInstance.post(
    `${API_HOST}/v1/users/link_for_auth`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res
}
