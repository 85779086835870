import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { NetworkErrorActions, NetworkErrorState } from './actionTypes'

const networkErrorReducer: SliceCaseReducers<NetworkErrorState> = {
  [NetworkErrorActions.SET_NO_CONNECTION]: (
    state: NetworkErrorState,
    action: PayloadAction<boolean>
  ) => ({
    ...state,
    connectionError: action.payload,
  }),
}

export default networkErrorReducer
