
import { PaymentMode } from '@_types/index'

export const UPSALE_PRICES = {
  UPSELL_PHRS_VRB_PRICE_999: {
    productId: 'UPSELL_PHRS_VRB_PRICE_999',
    amount: 999,
    pandaId: '7906cc2e-4225-42ce-a573-81efc95d7f34',
    firstPayment: 999,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
  },
}

export const PHRS_VRB_PRODUCTS_IDS = Object.keys(UPSALE_PRICES)
