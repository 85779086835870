import { Component } from 'react'

interface State {
  hasError: boolean
}

interface Props {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error)
    // eslint-disable-next-line no-console
    console.log('Error info:', errorInfo)
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <div>Something went wrong.</div>
    }

    return children
  }
}

export default ErrorBoundary
