import { Trans } from '@lingui/macro'

import { SUPPORT_EMAIL } from './common'

// eslint-disable-next-line import/prefer-default-export
export const creditsQuestions = [
  {
    title: <Trans id="What are Credits?" />,
    content: (
      <p>
        <Trans>
          When buying a Tutoring Subscription, you automatically get ‘Credits’
          added to your Credit Balance, depending on the Subscription you chose.
          Credits - are your internal currency, which can be used as ‘payment’
          for your lessons with tutors.
        </Trans>
      </p>
    ),
    id: 1,
  },
  {
    title: <Trans id="How do the Credits work?" />,
    content: (
      <p>
        <Trans>
          Your Credits must be used before the end of your Subscription.
          Otherwise the lessons will disappear.
        </Trans>
      </p>
    ),
    id: 2,
  },
  {
    title: <Trans id="How do I use Credits?" />,
    content: (
      <p>
        <Trans>
          Every time you book a lesson, the following number of credits gets
          withdrawn from your account: <br />
          1 lesson, 25 min - 1 credit
          <br /> 1 lesson, 50 min - 2 credits
          <br /> 1 lesson, 90 min - 3 credits
          <br /> If you cancel your lesson 24 hours before the lesson or
          earlier, credits are returned to your account automatically. If you
          cancel later than that, credits do not get returned to your account.
        </Trans>
      </p>
    ),
    id: 3,
  },
  {
    title: <Trans id="What if I run out of Credits?" />,
    content: (
      <p>
        <Trans>
          In case you run out of Credits before the Subscription expiration
          date, you may upgrade your Subscription or purchase more Credits by
          contacting your Manager in WhatsApp or by email, or by contacting the
          Support Team at {SUPPORT_EMAIL}
        </Trans>
      </p>
    ),
    id: 4,
  },
]
