import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { User } from 'firebase/auth'

import { Course } from '@api/courses/getActiveCourse'
import { CourseProgress } from '@api/courses/getCourseProgress'
import { Lesson } from '@api/courses/getLesson'
import { DailyPlan } from '@api/dailyPlan/getDailyPlan'

import { USER_TIMEZONE } from '@constants'

import {
  BookProgress,
  LearnedUnitTypes,
  LessonFeedback,
  ProgressStatuses,
  SpeakingResult,
} from '@_types/courses'

type AuthData = {
  token?: string
  user?: User | null
}

export type requestErrorHandler = (error: AxiosError) => void

type CoursesList = {
  courses: (Course & { added_at?: string })[]
}

type ErrorResponse = {
  message?: string
  code?: number
}

export interface CourseApiInterface {
  getCoursesList(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<CoursesList>>

  getIntroLesson(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Lesson & ErrorResponse>>

  sendSpeakingFile(params: {
    courseId: string
    lessonId: number | string
    quizId: number | string
    blob: Blob
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<SpeakingResult & ErrorResponse>>

  sendLessonFeedback(params: {
    feedback: LessonFeedback
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<SpeakingResult & ErrorResponse>>

  addUserCourses(params: {
    courses: { action: string; id: string }[]
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<CoursesList>>

  getCourseData(params: {
    courseId: string
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Course & { message?: string }>>

  getActiveCourseData(params: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Course & { message?: string }>>

  getCourseProgress(params: {
    courseId: string
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<CourseProgress & ErrorResponse>>

  getDailyPlan(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<DailyPlan & { message?: string }>>

  getDailyPlanProgress(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<DailyPlan & { message?: string }>>

  getCourseLesson(params: {
    courseId: string
    lessonId: string
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Lesson & { message?: string }>>

  setProgress(params: {
    courseId: string
    lessonId: number
    learnedType: LearnedUnitTypes
    stepId?: number
    quizId?: number
    bookProgress?: BookProgress
    status: ProgressStatuses
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<Record<string, any>>
}

const addRetry = (client: AxiosInstance) => {
  axiosRetry(client, {
    retries: 3,
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
  })
}

export default class CourseApi implements CourseApiInterface {
  private apiUrl = process.env.NEXT_PUBLIC_API_COURSES_HOST || ''

  // eslint-disable-next-line no-useless-constructor
  constructor(private authData: AuthData) {}

  async init(): Promise<AxiosInstance> {
    const headers: Record<string, any> = {
      'Content-Type': 'application/json',
      'x-time-location': USER_TIMEZONE,
    }

    const { user, token: userToken } = this.authData

    const token = userToken || (await user?.getIdToken())

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    return axios.create({
      baseURL: this.apiUrl,
      headers,
    })
  }

  static getShortCoursesList() {
    return axios.get(
      `${process.env.NEXT_PUBLIC_API_COURSES_HOST}/v1/courses/short`
    )
  }

  async getIntroLesson(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Lesson & ErrorResponse>> {
    const onError = params?.onError
    const controller = params?.controller

    const client = await this.init()

    addRetry(client)

    return client
      .get<Lesson>('/v1/courses/lessons/intro', {
        signal: controller?.signal,
      })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async sendSpeakingFile(params: {
    courseId: string
    lessonId: number | string
    quizId: number | string
    blob: Blob
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<SpeakingResult & ErrorResponse>> {
    const { courseId, lessonId, quizId, blob, onError, controller } = params

    const client = await this.init()

    const data = new FormData()
    data.append('file', blob)

    return client
      .post(
        `${process.env.NEXT_PUBLIC_API_COURSES_HOST}/v1/users/speaking/${courseId}/${lessonId}/${quizId}`,
        data,
        {
          signal: controller?.signal,
        }
      )
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async sendLessonFeedback(params: {
    feedback: LessonFeedback
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<SpeakingResult & ErrorResponse, any>> {
    const { feedback, controller, onError } = params

    const client = await this.init()

    return client
      .post(
        `${process.env.NEXT_PUBLIC_API_COURSES_HOST}/v1/courses/feedback`,
        feedback,
        {
          signal: controller?.signal,
        }
      )
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getCoursesList(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<CoursesList>> {
    const client = await this.init()

    addRetry(client)

    return client
      .get<CoursesList>('/v1/courses', {
        signal: params?.controller?.signal,
      })
      .catch((err) => {
        if (params?.onError) params?.onError(err)
        return err.response
      })
  }

  async addUserCourses(params: {
    courses: { action: string; id: string; current_user_level?: string }[]
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<CoursesList>> {
    const { courses, onError, controller } = params

    const client = await this.init()

    return client
      .post(
        '/v1/courses/switcher',
        {
          courses,
        },
        {
          signal: controller?.signal,
        }
      )
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getDailyPlan(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<DailyPlan & ErrorResponse>> {
    const onError = params?.onError
    const controller = params?.controller

    const client = await this.init()

    addRetry(client)

    return client
      .get<DailyPlan>('/v1/courses/lessons/dailyplan', {
        signal: controller?.signal,
      })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getDailyPlanProgress(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<DailyPlan & ErrorResponse>> {
    const onError = params?.onError
    const controller = params?.controller

    const client = await this.init()

    addRetry(client)

    return client
      .get<DailyPlan>('/v1/users/lessons/dailyplan/progress', {
        signal: controller?.signal,
      })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getCourseData(params: {
    courseId: string
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Course & ErrorResponse>> {
    const { courseId, onError, controller } = params

    const client = await this.init()

    addRetry(client)

    return client
      .get<Course>(`/v1/users/lessons/${courseId}`, {
        signal: controller?.signal,
      })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getActiveCourseData(params?: {
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Course & ErrorResponse>> {
    const onError = params?.onError
    const controller = params?.controller

    const client = await this.init()

    addRetry(client)

    return client
      .get<{ link: string }>('/v1/courses/lessons', {
        signal: controller?.signal,
        params: {
          redirect: false,
        },
      })
      .then((res) => axios.get<Course>(res.data.link))
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getCourseProgress(params: {
    courseId: string
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<CourseProgress & ErrorResponse>> {
    const onError = params?.onError
    const controller = params?.controller

    const client = await this.init()

    addRetry(client)

    return client
      .get<Course>(`/v1/users/lessons/${params.courseId}/progress`, {
        signal: controller?.signal,
      })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async getCourseLesson(params: {
    courseId: string
    lessonId: string
    onError?: requestErrorHandler
    controller?: AbortController
  }): Promise<AxiosResponse<Lesson & ErrorResponse>> {
    const { courseId, lessonId, onError, controller } = params

    const client = await this.init()

    addRetry(client)

    return client
      .get<Lesson>(`/v1/users/steps/${courseId}/${lessonId}`, {
        signal: controller?.signal,
      })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }

  async setProgress(params: {
    courseId: string
    lessonId: number
    learnedType: LearnedUnitTypes
    bookProgress?: BookProgress
    stepId?: number
    quizId?: number
    status: ProgressStatuses
    onError?: requestErrorHandler
    controller?: AbortController
    completeAllInLesson?: boolean
  }): Promise<Record<string, any>> {
    const {
      learnedType,
      courseId,
      lessonId,
      stepId,
      quizId,
      bookProgress,
      status,
      onError,
      controller,
      completeAllInLesson,
    } = params

    const body: Record<string, any> = {
      course_id: courseId,
      lesson_id: lessonId,
      learn_type: learnedType,
      created_at: Math.round(Date.now() / 1000), // timestamp in seconds
      id: crypto.randomUUID().toUpperCase(),
      status,
    }

    if (stepId) body.step_id = stepId
    if (quizId) body.quiz_id = quizId
    if (bookProgress) body.book_progress = bookProgress
    if (completeAllInLesson) body.complete_all_in_lesson = completeAllInLesson

    const client = await this.init()

    return client
      .put('/v1/courses/progress', body, { signal: controller?.signal })
      .catch((err) => {
        if (onError) onError(err)
        return err.response
      })
  }
}
