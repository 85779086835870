export const LANDING_NAME_MAIN_PAGE = 'main-page'
export const START_ONBOARDING = 'start onboarding'
export const LANDING_CLICKED_CTA = 'landing_clicked_cta'
export const CLICKED_PLAY_VIDEO = 'gen_clicked_play_video'

export const GROWTH_BOOK_DEFAULT_VALUE = 'default'

export enum CTA_BUTTON_LOCATION {
  hero = 'hero',
  ctaBlock = 'call-to-action-block',
}
