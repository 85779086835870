export interface Subscription {
  state: string
  status?: string
  product_id: string
  duration: string
  price: number
  order_id: string
  payment_type: string
  price_currency_code: string
  price_currency_symbol: string
  subscription_id: string
  product?: Record<string, any>
  cancelled_message?: string
  expiry_time?: string
  is_trial_period?: boolean
  will_be_charged: number
  platform?: string
  new_duration?: string
  new_price?: number
  new_price_currency_code?: string
  new_price_currency_symbol?: string
  new_product_id?: string
  start_new_product?: string
}

export type Product = {
  category: string
  download_url: string
  duration: number
  id: string
  quantity: number
  status: string
  term: string
  title: string
  trial: boolean
  type: string
}

export enum SubscriptionStatus {
  FREE = 'free',
  TRIAL = 'trial',
  PREMIUM = 'premium',
}

export type PricingCardSubscriptions =
  | 'promova_49.99_USD_90_days_intro_39.99'
  | 'promova_49.99_GBP_90_days_intro_39.99'
  | 'promova_49.99_EUR_90_days_intro_39.99'
  | 'promova_129.99_USD_year_intro_83.99'
  | 'promova_129.99_GBP_year_intro_83.99'
  | 'promova_129.99_EUR_year_intro_83.99'
  | 'promova_299.99_USD_lifetime'
  | 'promova_299.99_GBP_lifetime'
  | 'promova_299.99_EUR_lifetime'
