import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { QuizzActions, QuizzState } from '@reduxStore/reducers/quizz/types'

export const initialState: QuizzState = {
  flowId: '',
}

const reducers: SliceCaseReducers<QuizzState> = {
  [QuizzActions.SET_FLOW_ID]: (
    state: QuizzState,
    action: PayloadAction<QuizzState['flowId']>
  ) => ({
    ...state,
    flowId: action.payload,
  }),
}

export default reducers
