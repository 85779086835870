let timeout = 59 // 59 seconds
let extraTimeout = 529 // 29 seconds

if (process.env.NEXT_PUBLIC_LIVE_MODE === 'true') {
  timeout = 599 // 9 minutes 59 seconds
  extraTimeout = 299 // 4 minutes 59 seconds
}

export const DISCOUNT_TIMEOUT = timeout
export const EXTRA_DISCOUNT_TIMEOUT = extraTimeout

export const DISCOUNT_TIMER_NAME = 'discountTimer_2'
export const EXTRA_DISCOUNT_TIMER_NAME = 'extraDiscountTimer_2'

export const PHRASAL_VERBS_DROPBOX =
  'https://www.dropbox.com/s/3v83r5jb6geki2p/WB_Phrasal_verbs_notebook.pdf?dl=0'

export const IRREGULAR_VERBS_DROPBOX =
  'https://www.dropbox.com/s/ilgtzz5wskw3ukl/WB_Irregular_verbs_notebook.pdf?dl=0'
