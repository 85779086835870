import { PaymentMode, Total } from '_types'

const mult1 = 2.25
const mult2 = 0.5

const GATE_FIXED_FEE = 30 // 0.3$
const GATE_RATIO_FEE = 0.029 // 2.9%

const getValueOneTimePayment = (amount: number): number =>
  amount - GATE_FIXED_FEE - amount * GATE_RATIO_FEE

const getValueSubscriptionPrice = (
  firstPayment: number,
  secondPayment: number,
  mult = 1
): number => {
  if (firstPayment === 0) {
    const firstValue = secondPayment - GATE_FIXED_FEE
    const secondValue = secondPayment * GATE_RATIO_FEE

    const value = (firstValue - secondValue) * mult * mult2

    return value
  }

  const firstValue =
    firstPayment - GATE_FIXED_FEE - firstPayment * GATE_RATIO_FEE

  const secondValue =
    secondPayment - GATE_FIXED_FEE - secondPayment * GATE_RATIO_FEE

  const value = firstValue + secondValue * mult * mult2

  return value
}

export const getPriceValue = (
  total: Total
): { fullLtv: number; percentLtv: number } => {
  const { amount, firstPayment, secondPayment, paymentMode } = total

  let value = amount
  switch (paymentMode) {
    case PaymentMode.oneTime:
      value = getValueOneTimePayment(amount)
      break

    case PaymentMode.subscription:
    case PaymentMode.twoForOneSubscription:
    case PaymentMode.noIntroSubscription:
      value = getValueSubscriptionPrice(firstPayment, secondPayment, mult1)
      break

    default:
      break
  }

  const cents = Math.round(value)
  const dollars = cents / 100
  const LTV_10_PERCENT_LESS = dollars * 0.9

  return { percentLtv: LTV_10_PERCENT_LESS, fullLtv: dollars }
}

export default getPriceValue
