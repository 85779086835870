import { useQueryClient } from '@tanstack/react-query'

import { mainHttpClient } from '@api/OpenApiClient'
import { GET_PROFILE_QUERY_KEY } from '@api/profile/getProfile'
import { paths } from '@api/schema/schema'

import { useMutationFactory } from '@hooks/dataFetching/useQueryFactory'

const QUERY_KEY = '/v1/profiles/keeper/{key}'

type Payload = {
  key: paths[typeof QUERY_KEY]['put']['parameters']['path']['key']
  value: string
}

const updateKeeper = async ({ key, value }: Payload) => {
  const { data } = await mainHttpClient.put(QUERY_KEY, {
    params: { path: { key } },
    body: { value },
  })

  return data
}

const useUpdateKeeper = () => {
  const queryClient = useQueryClient()

  return useMutationFactory(updateKeeper)({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_PROFILE_QUERY_KEY] })
    },
  })
}

export { updateKeeper, useUpdateKeeper }
