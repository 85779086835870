import { createSlice } from '@reduxjs/toolkit'

import { HelpersState } from '@reduxStore/reducers/helpers/types'

import reducers from './helpersReducer'


const initialState: HelpersState = {
  disableScroll: () => null,
  enableScroll: () => null,
}

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState,
  reducers,
})

export const { setHelpers } = helpersSlice.actions

export default helpersSlice.reducer
