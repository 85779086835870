import { coursesHttpClient } from '@api/OpenApiClient'
import { paths } from '@api/schema/schema-courses'

import { UseQueryOptions } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

const DAILY_PLAN_QUERY_KEY = '/v1/courses/lessons/dailyplan'

type DailyPlan =
  paths[typeof DAILY_PLAN_QUERY_KEY]['get']['responses']['200']['content']['application/json']

const getDailyPlan = async () => {
  const { data } = await coursesHttpClient.get(DAILY_PLAN_QUERY_KEY)
  return data
}

const useDailyPlan = (
  config?: UseQueryOptions<
    paths[typeof DAILY_PLAN_QUERY_KEY]['get'],
    Awaited<ReturnType<typeof getDailyPlan>>,
    unknown,
    string
  >
) => useQueryFactory([DAILY_PLAN_QUERY_KEY], getDailyPlan)(config)

export { getDailyPlan, useDailyPlan, type DailyPlan }
