import { useEffect, useRef } from 'react'

const useAddBodyClassName = (classNames = '') => {
  const previousClassesRef = useRef<string[]>([])

  useEffect(() => {
    const body = document?.body
    const defaultClass = 'body_black'

    const classes = classNames?.split(' ').filter((item) => item.trim() !== '')

    if (!classes.length) {
      classes.push(defaultClass)
    }

    const classesToRemove = previousClassesRef.current.filter(
      (item) => !classes.includes(item)
    )

    if (classesToRemove.length) {
      body?.classList.remove(...classesToRemove)
    }

    body?.classList.add(...classes)

    previousClassesRef.current = classes

    return () => {
      body?.classList.remove(...classes)
    }
  }, [classNames])
}

export default useAddBodyClassName
