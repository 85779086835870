export const AM_PM_COUNTRIES = [
  'AU', // Australia
  'BD', // Bangladesh
  'CA', // Canada
  'CO', // Colombia
  'EG', // Egypt
  'HN', // Honduras
  'IE', // Ireland
  'IN', // India
  'JO', // Jordan
  'MX', // Mexico
  'MY', // Malaysia
  'NI', // Nicaragua
  'NZ', // New Zealand
  'PH', // Philippines
  'PK', // Pakistan
  'SA', // Saudi Arabia
  'SV', // El Salvador
  'US', // USA
]

export const USER_NAME_ID = 'user_name'

export const HOME_WORK_BUCKET = 'homework'

export const FACEBOOK_APP_ID = '345253006095753'

export const languagesList: Record<
  string,
  { label: string; shortLabel: string }
> = {
  en: { label: 'English', shortLabel: 'EN' },
  uk: { label: 'Українська', shortLabel: 'UA' },
  es: { label: 'Español', shortLabel: 'ES' },
  pt: { label: 'Português', shortLabel: 'PT' },
  fr: { label: 'Français', shortLabel: 'FR' },
  tr: { label: 'Türkçe', shortLabel: 'TR' },
  it: { label: 'Italiano', shortLabel: 'IT' },
  de: { label: 'Deutsch', shortLabel: 'DE' },
  pl: { label: 'Polski', shortLabel: 'PL' },
}

export const DISABLED_LOCALES: string[] = []
export const DISABLED_SEO_LOCALES: string[] = ['fr', 'tr', 'it', 'de', 'pl']

export const PROPOSED_SWITCH_LANGUAGE = 'proposed_change_language'

export const BLOCK_ANALYTICS = 'block_analytics'
export const BLOCK_FB_ANALYTICS = 'block_fb_analytics'
export const BLOCK_GOOGLE_ANALYTICS = 'block_google_analytics'
export const BLOCK_SNAP_PIXEL_ANALYTICS = 'block_snap_pixel_analytics'
export const BLOCK_AMPLITUDE_ANALYTICS = 'block_amplitude_analytics'
export const BLOCK_TWITTER_ANALYTICS = 'block_twitter_analytics'
export const BLOCK_TIKTOK_ANALYTICS = 'block_tiktok_analytics'
export const BLOCK_PINTEREST_ANALYTICS = 'block_pinterest_analytics'
