import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { CommonActions, CommonState } from './types'

const reducers: SliceCaseReducers<CommonState> = {
  [CommonActions.SET_IS_INITIALIZE_FACEBOOK]: (
    state: CommonState,
    action: PayloadAction<CommonState['isInitializeFacebook']>
  ) => ({
    ...state,
    isInitializeFacebook: action.payload,
  }),
}

export default reducers
