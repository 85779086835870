import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { ThemeActions, ThemeState } from './themeTypes'

const reducers: SliceCaseReducers<ThemeState> = {
  [ThemeActions.SET_SPECIAL_UI_THEME]: (
    state: ThemeState,
    action: PayloadAction<ThemeState['specialUITheme']>
  ) => ({
    ...state,
    specialUITheme: action.payload,
  }),
  [ThemeActions.TOGGLE_DYSLEXIC_MODE_ALLOWED]: (
    state: ThemeState,
    action: PayloadAction<ThemeState['isDyslexicModeAllowed']>
  ) => ({
    ...state,
    isDyslexicModeAllowed: action.payload,
  }),
}
export default reducers
