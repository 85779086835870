// hotjar
let hotjarSiteId = 0
let hotjarSV = 0

if (process.env.NEXT_PUBLIC_LIVE_MODE === 'true') {
  hotjarSiteId = 3064402
  hotjarSV = 6
}
export const HOTJAR_SITE_ID = hotjarSiteId
export const HOTJAR_SV = hotjarSV

export const TYPEFORM_URL = 'https://promova.typeform.com/to/'

// TODO: check if this value is still needed
// const SNAP_ID = 'adbd86b3-fcbb-451a-b2f6-9086f513c073'
