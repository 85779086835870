import { useContext, useEffect } from 'react'

import { Total } from '@_types/index'

import { AuthContext } from '@modules/Auth/AuthProvider'

export const ImpactIdentify = () => {
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const { ire } = window as any

    if (ire) {
      ire('identify', {
        customerId: user?.uid || '',
        customerEmail: user?.email || '',
      })
    }
  }, [user?.uid, user?.email])

  return null
}

const IMPACT_EVENT_ID = 40246

export const impactTrackConversion = (
  e: any,
  plan: Total,
  uid?: string | null,
  email?: string | null
) => {
  const { ire } = window as any
  if (ire) {
    ire(
      'trackConversion',
      IMPACT_EVENT_ID,
      {
        orderId: e?.data?.order?.order_id,
        customerId: uid || '',
        customerEmail: email || '',
        // customerStatus: '', TODO: add customer status
        currencyCode: plan.currencyCode,
        // orderPromoCode: '', TODO: add promo code
        orderDiscount: (plan.amount - plan.firstPayment) / 100,
        items: [
          {
            subTotal: plan.amount / 100,
            category: plan.productCategory,
            sku: plan.productId,
            quantity: 1,
            name: plan.productId,
          },
        ],
      },
      {
        verifySiteDefinitionMatch: true,
      }
    )
  }
}
