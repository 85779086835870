export const CLOSE_WEBVIEW = 'close_webview'
export const BOOKING_COMPLETED_WEBVIEW = 'booking_completed_webview'
export const GROUP_LESSONS_CHECKOUT_STARTED = 'group_lessons_checkout_started'
export const GROUP_LESSONS_SUCCESSFUL_PURCHASED =
  'group_lessons_successful_purchased'
export const GROUP_LESSONS_BOOK_CLICKED = 'group_lessons_book_clicked'
export const GROUP_LESSONS_PRICING_OPENED = 'group_lessons_pricing_opened'

export const ONEXONE_PRICING_OPENED = 'onexone_pricing_opened'
export const ONEXONE_CHECKOUT_STARTED = 'onexone_lessons_checkout_started'
export const ONEXONE_SUCCESSFUL_PURCHASED =
  'onexone_lessons_successful_purchased'
export const ONEXONE_BOOK_CLICKED = 'onexone_lessons_book_clicked'
export const ONEXONE_ACCOUNT_CLICKED = 'onexone_lessons_account_clicked'
export const ONEXONE_CREDITS_SUCCESSFUL_PURCHASE =
  'onexone_credits_successful_purchased'
export const GROUP_CREDITS_SUCCESSFUL_PURCHASE =
  'group_credits_successful_purchased'
