import classNames from 'classnames'
import { FC } from 'react'

import styles from './snackbar.module.scss'

interface Props {
  className?: string
}

const SnackBar: FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.snack_bar, className)}>{children}</div>
)

export default SnackBar
