import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import {
  AmplitudeActions,
  AmplitudeState,
} from '@reduxStore/reducers/amplitude/types'

const reducers: SliceCaseReducers<AmplitudeState> = {
  [AmplitudeActions.SET_IS_AMPLITUDE_INITED]: (
    state: AmplitudeState,
    action: PayloadAction<AmplitudeState['amplitudeInited']>
  ) => ({
    ...state,
    amplitudeInited: action.payload,
  }),
}

export default reducers
