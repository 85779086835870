import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { ROUTES_CLIENT_SIDE_REDIRECT_TO_DEFAULT_LOCALE } from '@constants_folder/routes'

export const useRedirectToDefaultLocale = () => {
  const { locale, defaultLocale, pathname, asPath, push, query } = useRouter()

  useEffect(() => {
    if (
      locale !== defaultLocale &&
      ROUTES_CLIENT_SIDE_REDIRECT_TO_DEFAULT_LOCALE.includes(pathname)
    ) {
      push({ pathname, query }, asPath, { locale: defaultLocale })
    }
  }, [asPath, defaultLocale, locale, pathname, push, query])
}
