import axios, { AxiosResponse } from 'axios'

import { mainHttpClient } from '@api/OpenApiClient'
import axiosInstance from '@api/axiosInstanse'
import { paths } from '@api/schema/schema'

import { KeeperData, UseQueryOptionsWithParams } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

import { sendAxiosErrorAnalytics } from '@utils/analytics'
import { sleep } from '@utils/helpers'

const API_HOST = process.env.NEXT_PUBLIC_API_HOST

export const getProfile = async (
  token: string
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/profiles`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return {
    profile: res?.data,
    status: res?.status,
  }
}

export const createProfile = async (
  token: string,
  payload: { location: string }
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.post(`${API_HOST}/v1/profiles`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res?.data
}

export const editProfile = async (
  token: string,
  payload: Record<string, any>
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.patch(`${API_HOST}/v1/profiles`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res?.data
}

let retryTimes = 0

export const editProfileV1 = async (
  token: string,
  payload: Record<string, any>
): Promise<Record<string, any> | null> => {
  const _axiosInstance = axios.create()
  _axiosInstance.interceptors.response.use(undefined, async (err) => {
    if (err?.response?.status === 400) {
      await sleep(1000)

      retryTimes += 1

      if (retryTimes < 4) {
        _axiosInstance(err?.config)
      } else {
        throw err
      }
    }

    sendAxiosErrorAnalytics(err)
    throw err
  })

  const res = await axiosInstance.patch(`${API_HOST}/v1/profiles`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}

export const addDataToProfile = async (
  token: string,
  data: KeeperData
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.put(
    `${API_HOST}/v1/profiles/keeper/${data.key}`,
    { value: data.payload },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res
}

export const KEEPER_QUERY_KEY = '/v1/profiles/keeper/{key}'

export const getDataFromKeeper = async (
  params: paths[typeof KEEPER_QUERY_KEY]['get']['parameters']
) => {
  const res = await mainHttpClient.get(KEEPER_QUERY_KEY, {
    params,
  })

  if (res?.response?.status === 404) {
    return null
  }

  return res?.data
}

type KeeperConfig = UseQueryOptionsWithParams<
  paths[typeof KEEPER_QUERY_KEY]['get'],
  Awaited<ReturnType<typeof getDataFromKeeper>>
> & { userId: string }

export const useKeeperValue = ({ params, userId, reactQuery }: KeeperConfig) =>
  useQueryFactory([KEEPER_QUERY_KEY, params?.path?.key, userId], () =>
    getDataFromKeeper(params)
  )(reactQuery as any)

export const getAvailableAvatars = async (
  token: string
): Promise<AxiosResponse> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/profiles/avatars`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}
