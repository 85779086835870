import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'

import CourseApi from '@api/course'
import { Course } from '@api/courses/getActiveCourse'
import { CourseProgress } from '@api/courses/getCourseProgress'
import { Lesson } from '@api/courses/getLesson'
import {
  DailyPlan,
  getDailyPlan as fetchDailyPlan,
} from '@api/dailyPlan/getDailyPlan'
import {
  DailyPlanProgress,
  getDailyPlanProgress as fetchDailyPlanProgress,
} from '@api/dailyPlan/getDailyPlanProgress'

import { CourseActions, CoursesState } from '@reduxStore/reducers/courses/types'
import { ProfileState } from '@reduxStore/reducers/profile/types'

import {
  BookProgress,
  LearnedUnitTypes,
  ProgressStatuses,
} from '@_types/courses'

export const getCoursesData = createAsyncThunk<
  Pick<CoursesState, 'coursesList' | 'userCourses'>,
  User | null | undefined
>(CourseActions.GET_COURSES_LIST, async (user = null) => {
  const coursesApi = new CourseApi({ user })

  const { data } = await coursesApi.getCoursesList({
    onError: () => {
      throw new Error('Error while getting courses list')
    },
  })
  const courses = data?.courses

  const userCoursesData = courses.filter((courseData) => courseData.is_select)

  return {
    coursesList: courses,
    userCourses: userCoursesData,
  }
})

export const getActiveCourse = createAsyncThunk<
  { course: Course; analytics: { course: string; userLevel: string } },
  User | null | undefined,
  { state: { profile: ProfileState } }
>(CourseActions.GET_ACTIVE_COURSE, async (user, { getState }) => {
  const coursesApi = new CourseApi({ user })

  const { data } = await coursesApi.getActiveCourseData({
    onError: () => {
      throw new Error('Error while getting active course')
    },
  })

  const { userProfile } = getState().profile

  const courseName = data?.from
    ? `${data.from}-${data.target} (${data.name})`
    : 'no_info'

  const level = userProfile?.language_level?.find(
    (l) => l.language === data.target
  )

  const analytics = {
    course: courseName,
    userLevel: level?.level || 'no_info',
  }

  return { course: data, analytics }
})

export const getCourseProgress = createAsyncThunk<
  CourseProgress,
  { user: User | null | undefined; courseId: string }
>(CourseActions.GET_COURSE_PROGRESS, async ({ user = null, courseId }) => {
  const coursesApi = new CourseApi({ user })

  const { data } = await coursesApi.getCourseProgress({
    courseId,
    onError: () => {
      throw new Error('Error while getting course progress')
    },
  })

  return data
})

export const getDailyPlan = createAsyncThunk<
  DailyPlan | undefined,
  User | null | undefined
>(CourseActions.GET_DAILY_PLAN, async () => {
  const dailyPlan = await fetchDailyPlan()

  return dailyPlan
})

export const getDailyPlanProgress = createAsyncThunk<
  DailyPlanProgress | undefined,
  User | null | undefined
>(CourseActions.GET_DAILY_PLAN_PROGRESS, async () => {
  const dailyPlanProgress = await fetchDailyPlanProgress()

  return dailyPlanProgress
})

export const getLesson = createAsyncThunk<
  Lesson,
  { user: User | null | undefined; courseId: string; lessonId: string }
>(CourseActions.GET_LESSON, async ({ user = null, courseId, lessonId }) => {
  const coursesApi = new CourseApi({ user })

  const { data } = await coursesApi.getCourseLesson({
    courseId,
    lessonId,
    onError: () => {
      throw new Error('Error while getting lesson')
    },
  })

  return data
})

export type ProgressParams = {
  courseId: string
  lessonId: number
  learnedType: LearnedUnitTypes
  bookProgress?: BookProgress
  stepId?: number
  quizId?: number
  status: ProgressStatuses
  completeAllInLesson?: boolean
}

export const setLessonProgress = createAsyncThunk<
  Record<string, any>,
  {
    user: User | null | undefined
    params: ProgressParams
    controller?: AbortController
  }
>(CourseActions.SET_LESSON_STATUS, ({ user = null, params, controller }) => {
  const coursesApi = new CourseApi({ user })

  return coursesApi.setProgress({
    ...params,
    controller,
    onError: () => {
      throw new Error('Error while setting progress')
    },
  })
})
