import { t } from '@lingui/macro'

import {
  Currencies,
  CurrencyType,
  PaymentMode,
  ProductCategory,
  Total,
} from '@_types/index'

const twoLessonsMonth = t`2 lessons a month`
const fourLessonsMonth = t`4 lessons a month`
const sixLessonsMonth = t`6 lessons a month`
const eightLessonsMonth = t`8 lessons a month`
const twelveLessonsMonth = t`12 lessons a month`
const includes16CreditsMonth = t`16 credits a month (~8 hours)`
const includes24CreditsMonth = t`24 credits a month (~12 hours)`
const oneTrialLesson = t`1 trial lesson`
const yourFirstLesson = t`Your first lesson`
const bestOffer = t`Best Offer`
const startingDays = t`days`
const oneLessonWithTutor = t`One 50-minute lesson with a tutor`
const startingWeeks = t`weeks`
const startingYear = t`year`
const first12Weeks = t`First 12 weeks`

const oneCredit = t`1 credit`
const twoCredits = t`2 credits`
const threeCredits = t`3 credits`

const fourGroupLessonsMonth = t`4 group lessons a month`
const eightGroupLessonsMonth = t`8 group lessons a month`
const twelveGroupLessonsMonth = t`12 group lessons a month`
const sixteenGroupLessonsMonth = t`16 group lessons a month`
const twentyGroupLessonsMonth = t`20 group lessons a month`

export const currency: CurrencyType = new Proxy(
  {
    USD: {
      symbol: '$',
      code: 'USD',
    },
    EUR: {
      symbol: '€',
      code: 'EUR',
    },
    GBP: {
      symbol: '£',
      code: 'GBP',
    },
    UAH: {
      symbol: '₴',
      code: 'UAH',
    },
  },
  {
    get: (target: CurrencyType, p: Currencies) =>
      p in target
        ? target[p]
        : {
            symbol: '$',
            code: 'USD',
          },
  }
)

export const pricingPageCreditPlansPrices: Record<string, Total> = {
  'tutor_subs_4credits_usd_65.99_28d': {
    productId: 'tutor_subs_4credits_usd_65.99_28d',
    amount: 6599,
    solidId: 'a9126dd5-12dd-4e0a-94d7-2313f77dca5d',
    pandaId: '185e9bf7-fd77-4432-94bb-fa0e29011e49',
    firstPayment: 6599,
    secondPayment: 6599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: twoLessonsMonth, // 2 lessons 50 min each
    pricePerCredit: 16.49,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4credits_usd_65.99_28d_free_7d_trial': {
    productId: 'tutor_subs_4credits_usd_65.99_28d_free_7d_trial',
    amount: 6599,
    solidId: 'f0190c78-ce49-4ea9-8257-65b097bb3421',
    pandaId: '67087b51-b02f-434a-b1d3-d655b411b21e',
    firstPayment: 0,
    secondPayment: 6599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twoLessonsMonth, // 2 lessons 50 min each
    trialDescription: yourFirstLesson,
    pricePerCredit: 16.49,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8credits_usd_103.99_28d': {
    productId: 'tutor_subs_8credits_usd_103.99_28d',
    amount: 10399,
    solidId: '81a6839a-a122-4d26-b4fc-dca29382846f',
    pandaId: '5489e7e3-873b-47b2-b75d-edfe58a1c11e',
    firstPayment: 10399,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth, // 4 lessons 50 min each
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8credits_usd_103.99_28d_free_7d_trial': {
    productId: 'tutor_subs_8credits_usd_103.99_28d_free_7d_trial',
    amount: 10399,
    solidId: '4fe383c4-c8c2-4c0b-9295-f442a2c71bd7',
    pandaId: 'b0946b63-2b53-4d7c-81f4-b52770fd9f63',
    firstPayment: 0,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth, // 4 lessons 50 min each
    trialDescription: yourFirstLesson,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12credits_usd_131.99_28d': {
    productId: 'tutor_subs_12credits_usd_131.99_28d',
    amount: 13199,
    solidId: 'a9126dd5-12dd-4e0a-94d7-2313f77dca5d',
    pandaId: '1c4b4369-4f2b-4842-9293-0ac7881fb1b1',
    firstPayment: 13199,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: sixLessonsMonth, // 6 lessons 50 min each
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12credits_usd_131.99_28d_free_7d_trial': {
    productId: 'tutor_subs_12credits_usd_131.99_28d_free_7d_trial',
    amount: 13199,
    solidId: 'c32f4140-e74e-4c1b-8fdc-65c3ef0fe308',
    pandaId: '321b4b3c-6f58-494e-bff9-8fa77b9f73ed',
    firstPayment: 0,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: sixLessonsMonth, // 6 lessons 50 min each
    trialDescription: yourFirstLesson,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_16credits_usd_175.99_28d': {
    productId: 'tutor_subs_16credits_usd_175.99_28d',
    amount: 17599,
    solidId: 'a198e939-805e-473f-ad8e-c3bd55a110ea',
    pandaId: '30a75a4d-f71c-4797-a1be-e544f832da0b',
    firstPayment: 17599,
    secondPayment: 17599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: includes16CreditsMonth,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_16credits_usd_175.99_28d_free_7d_trial': {
    productId: 'tutor_subs_16credits_usd_175.99_28d_free_7d_trial',
    amount: 17599,
    solidId: 'b318979a-d0a3-4598-b593-7e08a026950a',
    pandaId: 'e39f57cd-8ae1-45ac-a50a-816443f9c5dc',
    firstPayment: 0,
    secondPayment: 17599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: includes16CreditsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_24credits_usd_239.99_28d': {
    productId: 'tutor_subs_24credits_usd_239.99_28d',
    amount: 23999,
    solidId: '179dd672-445d-42d6-9132-d282c2cc7b7c',
    pandaId: '669abd81-e90a-4704-82b2-55edaaf57bab',
    firstPayment: 23999,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: includes24CreditsMonth,
    pricePerCredit: 9.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_24credits_usd_239.99_28d_free_7d_trial': {
    productId: 'tutor_subs_24credits_usd_239.99_28d_free_7d_trial',
    amount: 23999,
    solidId: 'd59253ae-4848-4275-a74c-c150f7d5a7ac',
    pandaId: '16c68812-75f1-4378-aa5c-fd73ad9e12d7',
    firstPayment: 0,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: includes24CreditsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 9.99,
    productCategory: ProductCategory.tutors,
  },
}

export const oneXoneCreditsPrices = {
  'tutors_ups_1l_12.99USD': {
    productId: 'tutors_ups_1l_12.99USD',
    amount: 1299,
    solidId: '',
    pandaId: '5b8faf5b-b440-454c-9f22-5509b95bf4aa',
    firstPayment: 1299,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
    productDescription: oneCredit,
    productCategory: 'tutors',
  },
  'tutors_ups_2l_25.99USD': {
    productId: 'tutors_ups_2l_25.99USD',
    amount: 2599,
    solidId: '',
    pandaId: 'ef5ec2c3-eddf-492f-90c4-25e7fbcbaa48',
    firstPayment: 2599,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
    productDescription: twoCredits,
    productCategory: 'tutors',
  },
  'tutors_ups_3l_38.99USD': {
    productId: 'tutors_ups_3l_38.99USD',
    amount: 3899,
    solidId: '',
    pandaId: '3dc463f5-ce95-4788-94a6-19b0bf879c05',
    firstPayment: 3899,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
    productDescription: threeCredits,
    productCategory: 'tutors',
  },
}

export const ONE_X_ONE_CREDITS_PRICES = Object.values(oneXoneCreditsPrices)

export const groupCreditsPrices = {
  'tutors_ups_1grl_7.49USD': {
    productId: 'tutors_ups_1grl_7.49USD',
    amount: 749,
    solidId: 'da259b82-d677-4d44-885d-476124530efc',
    pandaId: '148db871-3c68-4c88-b19f-bdef9c80d709',
    firstPayment: 749,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
    productDescription: oneCredit,
    productCategory: 'tutors',
  },
  'tutors_ups_2grl_14.99USD': {
    productId: 'tutors_ups_2grl_14.99USD',
    amount: 1499,
    solidId: 'df94fdd4-4046-435e-ac46-5cb1ea87f66b',
    pandaId: '68dfb567-bc5a-410e-b6ef-d84067e449cb',
    firstPayment: 1499,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
    productDescription: twoCredits,
    productCategory: 'tutors',
  },
  'tutors_ups_3grl_22.49USD': {
    productId: 'tutors_ups_3grl_22.49USD',
    amount: 2249,
    solidId: 'a77d44d6-c8cc-4423-b2f6-756186acffc3',
    pandaId: '138b9aa3-d33e-4b4e-a40b-e32d46714c75',
    firstPayment: 2249,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: '$',
    currencyCode: 'USD',
    productDescription: threeCredits,
    productCategory: 'tutors',
  },
}
export const GROUP_CREDITS_PRICES = Object.values(groupCreditsPrices)

export const UKRAINIAN_SELF_LEARNING_PRICES: Record<string, Total> = {
  promova_121499_UAH_30_days_intro: {
    productId: 'promova_1214.99_UAH_30_days_intro',
    amount: 121499,
    pandaId: '05f6e65a-1fbf-4196-a825-c3addc0fa733',
    firstPayment: 28499,
    secondPayment: 121499,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.UAH.symbol,
    currencyCode: currency.UAH.code,
    productDescription: '1 month',
    trialDescription: 'Trial week',
    billingCyclePeriod: 'month',
    productCategory: ProductCategory.selfStudy,
  },
  promova_202999_UAH_84_days: {
    productId: 'promova_2029.99_UAH_84_days',
    amount: 202999,
    pandaId: '362d22ca-a3b6-4c4e-83f1-c80914a1b81b',
    firstPayment: 161999,
    secondPayment: 202999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.UAH.symbol,
    currencyCode: currency.UAH.code,
    productDescription: '12 weeks',
    trialDescription: first12Weeks,
    billingCyclePeriod: '12 weeks',
    productCategory: ProductCategory.selfStudy,
  },
  promova_121499_UAH_30_days: {
    productId: 'promova_1214.99_UAH_30_days',
    amount: 121499,
    pandaId: '85bbd72d-4595-4a8a-8d50-ae34bac25682',
    firstPayment: 80999,
    secondPayment: 121499,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.UAH.symbol,
    currencyCode: currency.UAH.code,
    productDescription: '1 month',
    trialDescription: 'First month',
    billingCyclePeriod: 'month',
    productCategory: ProductCategory.selfStudy,
  },
}

export const PRICES: Record<string, Total> = {
  SUBS_MONTH_2999_7DAY_699: {
    productId: 'SUBS_MONTH_2999_7DAY_699',
    amount: 2999,
    pandaId: '9fdd9d98-c00c-402c-8c3f-f94c8c64c140',
    firstPayment: 699,
    secondPayment: 2999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: '1 month',
    trialDescription: 'Trial week',
    billingCyclePeriod: 'month',
    productCategory: ProductCategory.selfStudy,
  },
  SUBS_12_WEEKS_4999_INTRO_3999: {
    productId: 'SUBS_12_WEEKS_4999_INTRO_3999',
    amount: 4999,
    pandaId: '2acd1ede-9414-4514-9822-9957dbe85e74',
    firstPayment: 3999,
    secondPayment: 4999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: '12 weeks',
    trialDescription: first12Weeks,
    billingCyclePeriod: '12 weeks',
    productCategory: ProductCategory.selfStudy,
  },
  SUBS_MONTH_2999_INTRO_1999: {
    productId: 'SUBS_MONTH_2999_INTRO_1999',
    amount: 2999,
    pandaId: 'f1a4ed4b-03ab-43b6-a7d8-474e331f5eff',
    firstPayment: 1999,
    secondPayment: 2999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: '1 month',
    trialDescription: 'First month',
    billingCyclePeriod: 'month',
    productCategory: ProductCategory.selfStudy,
  },
  'ei_49.99_usd_84_days_intro_27.99': {
    productId: 'ei_49.99_usd_84_days_intro_27.99',
    amount: 4999,
    solidId: '60995a9b-1a94-4daa-8bed-8b48769c583f',
    pandaId: '2e3d6467-776d-4b5d-b2e9-b3c83ed1a141',
    firstPayment: 2799,
    secondPayment: 4999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: '12 weeks',
    trialDuration: 12,
    trialPeriod: startingWeeks,
    trialDescription: first12Weeks,
    billingCyclePeriod: '12 weeks',
    productCategory: ProductCategory.selfStudy,
  },
  'ei_29.99_usd_4_weeks_intro_19.99': {
    productId: 'ei_29.99_usd_4_weeks_intro_19.99',
    amount: 2999,
    solidId: 'b2d520fc-2fe7-46c9-860e-faf1f3d6f31e',
    pandaId: '2a3425a4-f4a2-4eb4-b49d-c5a7dfa88a4c',
    firstPayment: 1999,
    secondPayment: 2999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: '4 weeks',
    trialDuration: 4,
    trialPeriod: startingWeeks,
    trialDescription: 'First 4 weeks',
    billingCyclePeriod: '4 weeks',
    productCategory: ProductCategory.selfStudy,
  },
  'ei_69.99_usd_12_weeks_intro_49.99': {
    productId: 'ei_69.99_usd_12_weeks_intro_49.99',
    amount: 6999,
    solidId: 'f9e0c35d-1ba5-410e-a67f-986824784e4a',
    pandaId: 'b729ba40-ca87-439a-8fec-7be589a729f5',
    firstPayment: 4999,
    secondPayment: 6999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: '12 weeks',
    trialDuration: 12,
    trialPeriod: startingWeeks,
    trialDescription: first12Weeks,
    billingCyclePeriod: '12 weeks',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_USD_365_days_intro_89.99': {
    productId: 'promova_129.99_USD_365_days_intro_89.99',
    amount: 12999,
    solidId: 'd29e2421-8ba2-4830-bacc-2c04a84103a8',
    pandaId: '0ce0f8cb-024a-4fdd-8121-fd5936e05136',
    firstPayment: 8999,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_GBP_365_days_intro_89.99': {
    productId: 'promova_129.99_GBP_365_days_intro_89.99',
    amount: 12999,
    solidId: 'fac88c65-046a-4762-9f3a-c0b3b197fb75',
    pandaId: '20189faf-f491-4ee0-8a1d-f1f07cb53667',
    firstPayment: 8999,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.GBP.symbol,
    currencyCode: currency.GBP.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_EUR_365_days_intro_89.99': {
    productId: 'promova_129.99_EUR_365_days_intro_89.99',
    amount: 12999,
    solidId: '60881a6c-4b2c-4d7f-81f4-b3738b4ac074',
    pandaId: '0b8a6e23-8c16-4cfe-a062-b54fdcde2fd2',
    firstPayment: 8999,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.EUR.symbol,
    currencyCode: currency.EUR.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_EUR_365_days_intro_64.99': {
    productId: 'promova_129.99_EUR_365_days_intro_64.99',
    amount: 12999,
    solidId: 'fe239107-6e33-4906-979e-75e4b67b44d0',
    pandaId: 'd0f42c18-3880-458a-b2bc-bc79a06edafb',
    firstPayment: 6499,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '€',
    currencyCode: 'EUR',
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: 'self study',
  },
  'promova_44.99_USD_90_days_intro_37.99': {
    productId: 'promova_44.99_USD_90_days_intro_37.99',
    amount: 4499,
    solidId: '3228e01f-2804-4c39-adf9-57941d292625',
    pandaId: '25d383c3-0b16-49d2-8c3a-2a2eb525a492',
    firstPayment: 3799,
    secondPayment: 4499,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_44.99_GBP_90_days_intro_37.99': {
    productId: 'promova_44.99_GBP_90_days_intro_37.99',
    amount: 4499,
    solidId: '1943cc7d-f3b6-4610-a8de-e3eabd9f959d',
    pandaId: '5d636193-fa83-4e2a-ac2c-d23155c2c7a4',
    firstPayment: 3799,
    secondPayment: 4499,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.GBP.symbol,
    currencyCode: currency.GBP.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_44.99_EUR_90_days_intro_37.99': {
    productId: 'promova_44.99_EUR_90_days_intro_37.99',
    amount: 4499,
    solidId: 'bbd86126-c7da-465a-8488-a9a85a636517',
    pandaId: '7f087431-e0f7-441f-a3c1-99385650d8e7',
    firstPayment: 3799,
    secondPayment: 4499,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.EUR.symbol,
    currencyCode: currency.EUR.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  SUBSCRIPTION_MONTH_2999_7DAY_100_TRIAL: {
    productId: 'SUBSCRIPTION_MONTH_2999_7DAY_100_TRIAL',
    amount: 2999,
    pandaId: 'a828f310-bd41-4433-b2c8-eee32db9fb8a',
    firstPayment: 100,
    secondPayment: 2999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: 'Premium App user',
    trialDescription: '1 week trial',
    productCategory: ProductCategory.selfStudy,
  },
  SUBSCRIPTION_MONTH_1999_7DAY_100_TRIAL: {
    productId: 'SUBSCRIPTION_MONTH_1999_7DAY_100_TRIAL',
    amount: 1999,
    pandaId: 'b1a9ff77-3aef-48dc-9e1a-6f42aa8e95db',
    firstPayment: 100,
    secondPayment: 1999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: 'Premium App user',
    trialDescription: '1 week trial',
    productCategory: ProductCategory.selfStudy,
  },
  SUBSCRIPTION_MONTH_3999_7DAY_100_TRIAL: {
    productId: 'SUBSCRIPTION_MONTH_3999_7DAY_100_TRIAL',
    amount: 3999,
    solidId: '',
    pandaId: '78118e37-389e-41df-8dd9-74e544efe546',
    firstPayment: 100,
    secondPayment: 3999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: 'Premium App user',
    trialDescription: '1 week trial',
    productCategory: ProductCategory.selfStudy,
  },
  SUBSCRIPTION_MONTH_5999_7DAY_100_TRIAL: {
    productId: 'SUBSCRIPTION_MONTH_5999_7DAY_100_TRIAL',
    amount: 5999,
    solidId: '',
    pandaId: '94e78af5-b829-4dd5-b0b4-afbfe67eb19f',
    firstPayment: 100,
    secondPayment: 5999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: 'Premium App user',
    trialDescription: '1 week trial',
    productCategory: ProductCategory.selfStudy,
  },
  WP_TUTOR_SUBS_2999_USD_7_DAYS_TRIAL: {
    productId: 'WP_TUTOR_SUBS_2999_USD_7_DAYS_TRIAL',
    amount: 2999,
    solidId: 'c120793a-f0f6-457e-b545-c704b6d3b3e2',
    pandaId: 'd9653893-1d09-4d36-b90c-f84758f6257b',
    firstPayment: 100,
    secondPayment: 2999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: oneTrialLesson,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x25_usd_65.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_4x25_usd_65.99_30d_2.99_7d_trial',
    amount: 6599,
    solidId: '6267e7ee-cb3a-41e4-95bb-71b5bc12f010',
    pandaId: 'f34ff256-2337-4f1e-abb2-d638db322c19',
    firstPayment: 299,
    secondPayment: 6599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 16.49,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x25_usd_103.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_8x25_usd_103.99_30d_2.99_7d_trial',
    amount: 10399,
    solidId: '68ca66ba-ff93-4685-80b3-73d4c17cc313',
    pandaId: '5c6aafce-0504-4101-9e91-7f635d131c1b',
    firstPayment: 299,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x25_usd_131.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_12x25_usd_131.99_30d_2.99_7d_trial',
    amount: 13199,
    solidId: 'dba90ff4-ed46-4bc6-be54-5a36284d7792',
    pandaId: '02c8d47d-c977-4c38-b4f8-651a8d64d8ff',
    firstPayment: 299,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    trialDescription: oneTrialLesson,
    labelText: bestOffer,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x25_usd_65.99_30d': {
    productId: 'tutor_subs_4x25_usd_65.99_30d',
    amount: 6599,
    solidId: '3d4d92af-f25d-40bf-b887-c01c3692587f',
    pandaId: '406bae1a-6682-4cde-96a1-2a125118bdbd',
    firstPayment: 6599,
    secondPayment: 6599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    pricePerCredit: 16.49,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x25_usd_103.99_30d': {
    productId: 'tutor_subs_8x25_usd_103.99_30d',
    amount: 10399,
    solidId: '98c86ba6-c215-4357-bb98-5409828778ef',
    pandaId: '91ae012b-b963-4314-9a77-3974da551902',
    firstPayment: 10399,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x25_usd_131.99_30d': {
    productId: 'tutor_subs_12x25_usd_131.99_30d',
    amount: 13199,
    solidId: '2835a086-eecf-4de0-9925-588a31763942',
    pandaId: '4c5bccf8-7738-480e-86eb-d506722973b8',
    firstPayment: 13199,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    labelText: bestOffer,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x25_usd_65.99_30d_free_7d_trial': {
    productId: 'tutor_subs_4x25_usd_65.99_30d_free_7d_trial',
    amount: 6599,
    solidId: 'f689fc6d-0c9e-4bba-96ed-c5cdd6d1c707',
    pandaId: '128bf2e1-4922-4853-b68f-b87fad409881',
    firstPayment: 0,
    secondPayment: 6599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 16.49,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x25_usd_103.99_30d_free_7d_trial': {
    productId: 'tutor_subs_8x25_usd_103.99_30d_free_7d_trial',
    amount: 10399,
    solidId: 'e332d0c14-c223-48bf-991f-76bc44549acb',
    pandaId: 'cc547353-b9f6-401f-bf99-d3298fab084d',
    firstPayment: 0,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x25_usd_131.99_30d_free_7d_trial': {
    productId: 'tutor_subs_12x25_usd_131.99_30d_free_7d_trial',
    amount: 13199,
    solidId: '6bfa7c44-edc8-450c-bcee-2ba79013ac84',
    pandaId: '398e8c1e-0e3c-4c45-934e-9bb7835e243c',
    firstPayment: 0,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 10.99,
    labelText: bestOffer,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x50_usd_175.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_8x50_usd_175.99_30d_2.99_7d_trial',
    amount: 17599,
    solidId: '5058d938-98bb-4c33-9531-62972de200a4',
    pandaId: '1de20b8e-7474-435a-8663-1eaa260642cf',
    firstPayment: 299,
    secondPayment: 17599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x50_usd_239.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_12x50_usd_239.99_30d_2.99_7d_trial',
    amount: 23999,
    solidId: '19384b66-5f57-4134-b5bd-c7893956db6d',
    pandaId: 'd7eb58aa-c156-44a1-ba64-dab11efc2aa2',
    firstPayment: 299,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    trialDescription: yourFirstLesson,
    labelText: bestOffer,
    pricePerCredit: 9.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x90_usd_339.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_12x90_usd_339.99_30d_2.99_7d_trial',
    amount: 33999,
    solidId: '60c8a50b-6fa7-4d37-90dd-bca8d53c3e17',
    pandaId: '14392b32-b252-4aca-aed1-bda1812e3f72',
    firstPayment: 299,
    secondPayment: 33999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    trialDescription: yourFirstLesson,
    labelText: bestOffer,
    pricePerCredit: 7.87,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x50_usd_175.99_30d': {
    productId: 'tutor_subs_8x50_usd_175.99_30d',
    amount: 17599,
    solidId: 'e27dc1b5-a3df-435a-ba81-6891dc6c2027',
    pandaId: '67fcd854-fbf0-4889-80ae-96347fc6d5b6',
    firstPayment: 17599,
    secondPayment: 17599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x50_usd_239.99_30d': {
    productId: 'tutor_subs_12x50_usd_239.99_30d',
    amount: 23999,
    solidId: 'aba64157-a829-445e-9292-e2146ea5e734',
    pandaId: '1e5db618-e5f4-4839-8f90-0afb5aacfa60',
    firstPayment: 23999,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    labelText: bestOffer,
    pricePerCredit: 9.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x90_usd_339.99_30d': {
    productId: 'tutor_subs_12x90_usd_339.99_30d',
    amount: 33999,
    solidId: '34e14c78-2d9e-4c73-a448-fe11e4d9afc1',
    pandaId: '09e32819-728c-49d9-a6cb-b03d03eab101',
    firstPayment: 33999,
    secondPayment: 33999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    labelText: bestOffer,
    pricePerCredit: 7.87,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x50_usd_103.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_4x50_usd_103.99_30d_2.99_7d_trial',
    amount: 10399,
    solidId: 'a2bbe401-ba69-4065-9fa5-ee34bd395463',
    pandaId: 'b7e8c799-dde6-4c87-bb67-2c55c6e01cbe',
    firstPayment: 299,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x90_usd_131.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_4x90_usd_131.99_30d_2.99_7d_trial',
    amount: 13199,
    solidId: '306fcf18-b9a2-45b7-a7d4-0cfc0169bfcd',
    pandaId: '0b59fd49-c10a-4a24-b8d7-1ee59efded94',
    firstPayment: 299,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x50_usd_103.99_30d': {
    productId: 'tutor_subs_4x50_usd_103.99_30d',
    amount: 10399,
    solidId: '18ce9d2b-903c-46b3-b381-f313eae2c063',
    pandaId: 'fccc66e3-754a-4f70-acf2-023b991eb545',
    firstPayment: 10399,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x50_usd_103.99_30d_free_7d_trial': {
    productId: 'tutor_subs_4x50_usd_103.99_30d_free_7d_trial',
    amount: 10399,
    solidId: 'a9126dd5-12dd-4e0a-94d7-2313f77dca5d',
    pandaId: 'dc545a79-16bf-4163-b6aa-0b9c51133637',
    firstPayment: 0,
    secondPayment: 10399,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 12.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x50_usd_175.99_30d_free_7d_trial': {
    productId: 'tutor_subs_8x50_usd_175.99_30d_free_7d_trial',
    amount: 17599,
    solidId: '301ef9db-52c4-407c-99b8-1ccc449ca3ed',
    pandaId: 'ad4cc9af-eaba-4098-a46b-0c28f64fa52c',
    firstPayment: 0,
    secondPayment: 17599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 10.99,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x90_usd_131.99_30d': {
    productId: 'tutor_subs_4x90_usd_131.99_30d',
    amount: 13199,
    solidId: '672fdf1e-a673-4874-8d44-c8b4868f0b8e',
    pandaId: 'ae9110ee-6de6-4a7c-b079-4d5e39aeb475',
    firstPayment: 13199,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    pricePerCredit: 9.17,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_4x90_usd_131.99_30d_free_7d_trial': {
    productId: 'tutor_subs_4x90_usd_131.99_30d_free_7d_trial',
    amount: 13199,
    solidId: '0746e0ac-2d19-4c6a-bd84-e0859a59ca34',
    pandaId: 'f47f7522-bd69-489b-b242-ec0c440ca1b9',
    firstPayment: 0,
    secondPayment: 13199,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: fourLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 9.17,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x50_usd_239.99_30d_free_7d_trial': {
    productId: 'tutor_subs_12x50_usd_239.99_30d_free_7d_trial',
    amount: 23999,
    solidId: '17ec193c-70a8-4c3d-a05e-e81970d9e8c0',
    pandaId: 'c5ca782a-627d-4b24-8126-0706a89e232a',
    firstPayment: 0,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 9.99,
    labelText: bestOffer,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x90_usd_239.99_30d_free_7d_trial': {
    productId: 'tutor_subs_8x90_usd_239.99_30d_free_7d_trial',
    amount: 23999,
    solidId: '1bdef8a0-9d93-4949-aeee-d3c380f3b3e9',
    pandaId: 'e97c798a-69b7-4474-b6db-25cfe358e31c',
    firstPayment: 0,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    trialDescription: oneTrialLesson,
    pricePerCredit: 8.33,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x90_usd_239.99_30d': {
    productId: 'tutor_subs_8x90_usd_239.99_30d',
    amount: 23999,
    solidId: 'c43ff779-04d7-4ca5-8bd7-d6a4966b29f0',
    pandaId: '3e90fdb6-b320-40c0-a729-91835444c9a8',
    firstPayment: 23999,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    pricePerCredit: 8.33,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_8x90_usd_239.99_30d_2.99_7d_trial': {
    productId: 'tutor_subs_8x90_usd_239.99_30d_2.99_7d_trial',
    amount: 23999,
    solidId: 'd0f0c8ce-a76c-4c73-84b1-f851d15c2f67',
    pandaId: 'a88a2d11-7fb3-4b84-ad58-bead2a3a93b5',
    firstPayment: 299,
    secondPayment: 23999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    trialDescription: yourFirstLesson,
    pricePerCredit: 8.33,
    productCategory: ProductCategory.tutors,
  },
  'tutor_subs_12x90_usd_339.99_30d_free_7d_trial': {
    productId: 'tutor_subs_12x90_usd_339.99_30d_free_7d_trial',
    amount: 33999,
    solidId: 'c45eb1f2-37cd-4eea-aff3-13d3ae97f57d',
    pandaId: '455b3073-30ac-4c36-99ff-1cb40a8bd211',
    firstPayment: 0,
    secondPayment: 33999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 7,
    trialPeriod: startingDays,
    billingCyclePeriod: 'month',
    productDescription: twelveLessonsMonth,
    trialDescription: yourFirstLesson,
    labelText: bestOffer,
    pricePerCredit: 7.87,
    productCategory: ProductCategory.tutors,
  },
  'tutor_onetime_1x50_usd_19.99': {
    productId: 'tutor_onetime_1x50_usd_19.99',
    amount: 1999,
    solidId: '',
    pandaId: 'acdc0542-8716-4794-b9c8-0a6301b5c9d1',
    firstPayment: 1999,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: oneLessonWithTutor,
    productCategory: ProductCategory.tutors,
  },
  'promova_129.99_usd_year_79.99': {
    productId: 'promova_129.99_usd_year_79.99',
    amount: 12999,
    solidId: '82a3154d-3a3e-43df-8320-47e70b36c78e',
    pandaId: '96496620-3e84-4099-9e81-e07b83537086',
    firstPayment: 7999,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_49.99_usd_3_months_27.99': {
    productId: 'promova_49.99_usd_3_months_27.99',
    amount: 4999,
    solidId: '9a619c57-945b-4997-b23c-a196624dce8c',
    pandaId: 'b4efd691-fb52-41aa-a15c-fbbea0c5548d',
    firstPayment: 2799,
    secondPayment: 4999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_29.99_usd_1_month_14.99': {
    productId: 'promova_29.99_usd_1_month_14.99',
    amount: 2999,
    solidId: '58fc0243-11a6-431c-98d2-9e6345adb45b',
    pandaId: '440a807c-18fc-4127-8fac-1c81c5cf4bf7',
    firstPayment: 1499,
    secondPayment: 2999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 30,
    trialPeriod: startingDays,
    billingCyclePeriod: '1 month',
    productDescription: '1 month subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_49.99_USD_90_days_intro_39.99': {
    productId: 'promova_49.99_USD_90_days_intro_39.99',
    amount: 4999,
    solidId: '20b88b2d-0aaa-4c93-8255-068c59392d05',
    pandaId: 'b7b1593d-72cd-488b-8887-79a6eecbffde',
    firstPayment: 3999,
    secondPayment: 4999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_49.99_GBP_90_days_intro_39.99': {
    productId: 'promova_49.99_GBP_90_days_intro_39.99',
    amount: 4999,
    solidId: '55a42648-36dc-4814-8e64-d31dc1730865',
    pandaId: '2aa98af2-45f5-4b8d-872a-bcea954d2267',
    firstPayment: 3999,
    secondPayment: 4999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.GBP.symbol,
    currencyCode: currency.GBP.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_49.99_EUR_90_days_intro_39.99': {
    productId: 'promova_49.99_EUR_90_days_intro_39.99',
    amount: 4999,
    solidId: '61e09bf3-0d27-4924-b8a3-eafbce8870bc',
    pandaId: 'cb42e880-f7be-49fa-af6e-d2d95bb98ee3',
    firstPayment: 3999,
    secondPayment: 4999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.EUR.symbol,
    currencyCode: currency.EUR.code,
    trialDuration: 90,
    trialPeriod: startingDays,
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_USD_year_intro_83.99': {
    productId: 'promova_129.99_USD_year_intro_83.99',
    amount: 12999,
    solidId: '21adea9c-4038-460e-b0c2-83c0aae838c1',
    pandaId: 'e06c509b-5166-4cc0-b0c5-9084e58eb3c6',
    firstPayment: 8399,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_GBP_year_intro_83.99': {
    productId: 'promova_129.99_GBP_year_intro_83.99',
    amount: 12999,
    solidId: '0f2e49e7-84c6-4c01-9a2f-13acc4b1ea5d',
    pandaId: 'bc783804-a1c4-4682-b69c-e51c7cf70bcc',
    firstPayment: 8399,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.GBP.symbol,
    currencyCode: currency.GBP.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_129.99_EUR_year_intro_83.99': {
    productId: 'promova_129.99_EUR_year_intro_83.99',
    amount: 12999,
    solidId: 'dc84f620-eef4-412c-91b4-40deac35d7bf',
    pandaId: '989406a7-1058-4c8e-8ca9-a9e03dc72ad6',
    firstPayment: 8399,
    secondPayment: 12999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.EUR.symbol,
    currencyCode: currency.EUR.code,
    trialDuration: 1,
    trialPeriod: startingYear,
    billingCyclePeriod: 'year',
    productDescription: 'Annual subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_299.99_USD_lifetime': {
    productId: 'promova_299.99_USD_lifetime',
    amount: 29999,
    solidId: 'f649708f-98ff-41a1-8715-c98de0691668',
    pandaId: '805129a7-46c1-4bef-b552-a5fb37188834',
    firstPayment: 29999,
    secondPayment: 29999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    billingCyclePeriod: 'lifetime',
    productDescription: 'Lifetime subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_299.99_GBP_lifetime': {
    productId: 'promova_299.99_GBP_lifetime',
    amount: 29999,
    solidId: '1f9e23a8-b699-47df-8777-649159dd9d62',
    pandaId: 'aa28985c-b827-43a2-b799-1b77a7f4f820',
    firstPayment: 29999,
    secondPayment: 29999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.GBP.symbol,
    currencyCode: currency.GBP.code,
    billingCyclePeriod: 'lifetime',
    productDescription: 'Lifetime subs',
    productCategory: ProductCategory.selfStudy,
  },
  'promova_299.99_EUR_lifetime': {
    productId: 'promova_299.99_EUR_lifetime',
    amount: 29999,
    solidId: '74acbe42-e9b6-4b8e-9bb5-8cf642da1e27',
    pandaId: '8cd733b3-64a0-4acf-bfa3-5c5b468fc690',
    firstPayment: 29999,
    secondPayment: 29999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: currency.EUR.symbol,
    currencyCode: currency.EUR.code,
    billingCyclePeriod: 'lifetime',
    productDescription: 'Lifetime subs',
    productCategory: ProductCategory.selfStudy,
  },
  'tutor_onetime_1x25_usd_9.99': {
    productId: 'tutor_onetime_1x25_usd_9.99',
    amount: 999,
    solidId: '2a462761-423a-44f9-8137-67230396b585',
    pandaId: '849806cc-0bb1-4766-84fe-f999b8df7632',
    firstPayment: 999,
    secondPayment: 0,
    paymentMode: PaymentMode.oneTime,
    currencySymbol: currency.USD.symbol,
    currencyCode: currency.USD.code,
    productDescription: oneLessonWithTutor,
    productCategory: ProductCategory.tutors,
  },
  'promova_39.99_USD_90_days_intro_11.99': {
    productId: 'promova_39.99_USD_90_days_intro_11.99',
    amount: 3999,
    solidId: '2c33b111-e32e-4997-961b-60b7fe6db4da',
    pandaId: '38a0b648-572b-419d-9122-cc65f6b6ba58',
    firstPayment: 1199,
    secondPayment: 3999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: '3 months',
    productDescription: '3 months subs',
    productCategory: 'self study',
  },
  'group_subs_4x60_usd_39.99_28d': {
    productId: 'group_subs_4x60_usd_39.99_28d',
    amount: 3999,
    solidId: '18a38fc5-7451-411e-8242-76a53e558068',
    pandaId: '9712fac5-f60f-4475-a14b-6677b3c217a7',
    firstPayment: 3999,
    secondPayment: 3999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: 'month',
    productDescription: fourGroupLessonsMonth,
    productCategory: 'tutors',
  },
  'group_subs_8x60_usd_59.99_28d': {
    productId: 'group_subs_8x60_usd_59.99_28d',
    amount: 5999,
    solidId: 'ff9999fb-2efa-4157-ad4e-428c17073efb',
    pandaId: 'a05248a5-5fd5-44a7-bf6f-5a61a02b1219',
    firstPayment: 5999,
    secondPayment: 5999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: 'month',
    productDescription: eightGroupLessonsMonth,
    productCategory: 'tutors',
  },
  'group_subs_12x60_usd_77.99_28d': {
    productId: 'group_subs_12x60_usd_77.99_28d',
    amount: 7799,
    solidId: 'a9e260f2-6b13-4efd-b1a3-c5d8a0910b3f',
    pandaId: '2e1d42e3-cee3-4b98-97b6-cbe720d56646',
    firstPayment: 7799,
    secondPayment: 7799,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: 'month',
    productDescription: twelveGroupLessonsMonth,
    productCategory: 'tutors',
  },
  'group_subs_20x60_usd_99.99_28d': {
    productId: 'group_subs_20x60_usd_99.99_28d',
    amount: 9999,
    solidId: '04f22478-dba3-4169-a0c5-ad93c5874559',
    pandaId: 'f7709788-03df-46dc-a136-5e4938eae4de',
    firstPayment: 9999,
    secondPayment: 9999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: 'month',
    productDescription: twentyGroupLessonsMonth,
    productCategory: 'tutors',
  },
  'tutor_subs_8l_159.99USD_30d': {
    productId: 'tutor_subs_8l_159.99USD_30d',
    amount: 15999,
    solidId: '5287f437-28ea-4ef5-84d2-38fc1a6c539e',
    pandaId: 'fc234702-4c9d-493a-807a-c7a1e9d9f704',
    firstPayment: 15999,
    secondPayment: 15999,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: 'month',
    productDescription: eightLessonsMonth,
    productCategory: 'tutors',
  },
  'tutor_subs_16grl_95.99USD_30d': {
    productId: 'tutor_subs_16grl_95.99USD_30d',
    amount: 9599,
    solidId: 'dd98cca1-bf3a-4ae0-9c4b-a6296ed55205',
    pandaId: 'fb29dfbc-e1e6-4a65-a6f1-984736251713',
    firstPayment: 9599,
    secondPayment: 9599,
    paymentMode: PaymentMode.subscription,
    currencySymbol: '$',
    currencyCode: 'USD',
    billingCyclePeriod: 'month',
    productDescription: sixteenGroupLessonsMonth,
    productCategory: 'tutors',
  },
  ...pricingPageCreditPlansPrices,
  ...oneXoneCreditsPrices,
  ...groupCreditsPrices,
  ...UKRAINIAN_SELF_LEARNING_PRICES,
}

export const PRICES_ECOMMERCE_DATA = {
  'tutor_subs_4x25_usd_65.99_30d_2.99_7d_trial': {
    lessonDuration: 25,
    lessonsPerMonth: 4,
  },
  'tutor_subs_8x25_usd_103.99_30d_2.99_7d_trial': {
    lessonDuration: 25,
    lessonsPerMonth: 8,
  },
  'tutor_subs_12x25_usd_131.99_30d_2.99_7d_trial': {
    lessonDuration: 25,
    lessonsPerMonth: 12,
  },
  'tutor_subs_4x25_usd_65.99_30d': {
    lessonDuration: 25,
    lessonsPerMonth: 4,
  },
  'tutor_subs_8x25_usd_103.99_30d': {
    lessonDuration: 25,
    lessonsPerMonth: 8,
  },
  'tutor_subs_12x25_usd_131.99_30d': {
    lessonDuration: 25,
    lessonsPerMonth: 12,
  },
  'tutor_subs_4x25_usd_65.99_30d_free_7d_trial': {
    lessonDuration: 25,
    lessonsPerMonth: 4,
  },
  'tutor_subs_8x25_usd_103.99_30d_free_7d_trial': {
    lessonDuration: 25,
    lessonsPerMonth: 8,
  },
  'tutor_subs_12x25_usd_131.99_30d_free_7d_trial': {
    lessonDuration: 25,
    lessonsPerMonth: 12,
  },
  'tutor_subs_8x50_usd_175.99_30d_2.99_7d_trial': {
    lessonDuration: 50,
    lessonsPerMonth: 8,
  },
  'tutor_subs_12x50_usd_239.99_30d_2.99_7d_trial': {
    lessonDuration: 50,
    lessonsPerMonth: 12,
  },
  'tutor_subs_12x90_usd_339.99_30d_2.99_7d_trial': {
    lessonDuration: 90,
    lessonsPerMonth: 12,
  },
  'tutor_subs_8x50_usd_175.99_30d': {
    lessonDuration: 50,
    lessonsPerMonth: 8,
  },
  'tutor_subs_12x50_usd_239.99_30d': {
    lessonDuration: 50,
    lessonsPerMonth: 12,
  },
  'tutor_subs_12x90_usd_339.99_30d': {
    lessonDuration: 90,
    lessonsPerMonth: 12,
  },
  'tutor_subs_4x50_usd_103.99_30d_2.99_7d_trial': {
    lessonDuration: 50,
    lessonsPerMonth: 4,
  },
  'tutor_subs_4x90_usd_131.99_30d_2.99_7d_trial': {
    lessonDuration: 90,
    lessonsPerMonth: 4,
  },
  'tutor_subs_4x50_usd_103.99_30d': {
    lessonDuration: 50,
    lessonsPerMonth: 4,
  },
  'tutor_subs_4x50_usd_103.99_30d_free_7d_trial': {
    lessonDuration: 50,
    lessonsPerMonth: 4,
  },
  'tutor_subs_8x50_usd_175.99_30d_free_7d_trial': {
    lessonDuration: 50,
    lessonsPerMonth: 8,
  },
  'tutor_subs_4x90_usd_131.99_30d': {
    lessonDuration: 90,
    lessonsPerMonth: 4,
  },
  'tutor_subs_4x90_usd_131.99_30d_free_7d_trial': {
    lessonDuration: 90,
    lessonsPerMonth: 4,
  },
  'tutor_subs_12x50_usd_239.99_30d_free_7d_trial': {
    lessonDuration: 50,
    lessonsPerMonth: 12,
  },
  'tutor_subs_8x90_usd_239.99_30d_free_7d_trial': {
    lessonDuration: 90,
    lessonsPerMonth: 8,
  },
  'tutor_subs_8x90_usd_239.99_30d': {
    lessonDuration: 90,
    lessonsPerMonth: 8,
  },
  'tutor_subs_8x90_usd_239.99_30d_2.99_7d_trial': {
    lessonDuration: 90,
    lessonsPerMonth: 8,
  },
  'tutor_subs_12x90_usd_339.99_30d_free_7d_trial': {
    lessonDuration: 90,
    lessonsPerMonth: 12,
  },
}
