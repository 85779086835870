import { useRouter } from 'next/router'
import { useEffect } from 'react'

import {
  BLOCK_AMPLITUDE_ANALYTICS,
  BLOCK_ANALYTICS,
  BLOCK_FB_ANALYTICS,
  BLOCK_GOOGLE_ANALYTICS,
} from '@promova/config/constants/common'

const useBlockAnalytics = () => {
  const { query } = useRouter()

  const blockAnalytics = query?.[BLOCK_ANALYTICS] === 'true'
  const blockFBAnalytics = query?.[BLOCK_FB_ANALYTICS] === 'true'
  const blockGoogleAnalytics = query?.[BLOCK_GOOGLE_ANALYTICS] === 'true'
  const blockAmplitudeAnalytics = query?.[BLOCK_AMPLITUDE_ANALYTICS] === 'true'

  useEffect(() => {
    if (blockAnalytics) {
      sessionStorage.setItem(BLOCK_ANALYTICS, 'true')
    }
    if (blockFBAnalytics) {
      sessionStorage.setItem(BLOCK_FB_ANALYTICS, 'true')
    }
    if (blockGoogleAnalytics) {
      sessionStorage.setItem(BLOCK_GOOGLE_ANALYTICS, 'true')
    }
    if (blockAmplitudeAnalytics) {
      sessionStorage.setItem(BLOCK_AMPLITUDE_ANALYTICS, 'true')
    }
  }, [
    blockAnalytics,
    blockFBAnalytics,
    blockGoogleAnalytics,
    blockAmplitudeAnalytics,
  ])
}

export default useBlockAnalytics
