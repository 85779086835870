import { t } from '@lingui/macro'

import Topics from '@templates/QuizV1/Topics/Topics'

const languagesAnswersOptions = [
  {
    id: 'en',
    text: `English`,
    image: '/flags/USA.svg',
    badgeText: t`popular`,
    eventData: 'English',
  },
  {
    id: 'ar',
    text: `Arabic`,
    image: '/flags/arabic.svg',
    eventData: 'Arabic',
  },
  {
    id: 'zh',
    text: `Chinese`,
    image: '/flags/chinese.svg',
    eventData: 'Chinese',
  },
  {
    id: 'fr',
    text: `French`,
    image: '/flags/France.svg',
    eventData: 'French',
  },
  {
    id: 'de',
    text: `German`,
    image: '/flags/Germany.svg',
    eventData: 'German',
  },
  {
    id: 'it',
    text: `Italian`,
    image: '/flags/italian.svg',
    eventData: 'Italian',
  },
  {
    id: 'ja',
    text: `Japanese`,
    image: '/flags/japanese.svg',
    eventData: 'Japanese',
  },
  {
    id: 'ko',
    text: `Korean`,
    image: '/flags/korean.svg',
    eventData: 'Korean',
  },
  {
    id: 'es',
    text: `Spanish`,
    image: '/flags/Spain.svg',
    eventData: 'Spanish',
  },
  {
    id: 'tr',
    text: `Turkish`,
    image: '/flags/turkish.svg',
    eventData: 'Turkish',
  },
  {
    id: 'uk',
    text: `Ukrainian`,
    image: '/flags/Ukraine.svg',
    eventData: 'Ukrainian',
  },
  {
    id: 'pt',
    text: `Portuguese`,
    image: '/flags/Portuguese.svg',
    eventData: 'Portuguese',
  },
  {
    id: 'asl',
    text: `American Sign Language`,
    image: '/flags/USA.svg',
    eventData: 'American Sign Language',
  },
  {
    id: 'latam',
    text: `Spanish (LATAM)`,
    image: '/flags/latam.svg',
    eventData: 'Spanish (LATAM)',
  },
  {
    id: 'brazil',
    text: `Portuguese (Brazil)`,
    image: '/flags/brazil.svg',
    eventData: 'Portuguese (Brazil)',
  },
]

const data = {
  quiz_id: 'courses_onboarding',
  survey_type: 'common',
  language_test_type: 'common', // without this value we can't save quiz result in db
  mainQuestions: [
    {
      id: 'target_language',
      type: 'single_choice',
      question: t`I want to learn:`,
      localizationKey: 'I want to learn_',
      category: 'survey',
      eventName: 'funnels_answered_target_lang',
      answersOptions: languagesAnswersOptions,
    },
    {
      id: 'source_language',
      type: 'single_choice',
      question: t`Teaching will be in...`,
      localizationKey: 'Teaching will be in_',
      category: 'survey',
      eventName: 'funnels_answered_source_lang',
      answersOptions: languagesAnswersOptions,
    },
    {
      id: 'motivation',
      type: 'multiple_choice',
      question: t`What is your motivation to learn target_language?`,
      description: t`You can choose more than one option`,
      localizationKey: 'What is your motivation to learn_',
      answerFromScreen: 'target_language',
      category: 'survey',
      eventName: 'funnels_answered_goals',
      answersOptions: [
        {
          id: 'career_job',
          text: t`Career, job opportunities`,
          icon: '💼',
          eventData: 'career, job opportunities',
        },
        {
          id: 'school',
          text: t`Education`,
          icon: '📘',
          eventData: 'education',
        },
        { id: 'culture', text: t`Culture`, icon: '🌏', eventData: 'culture' },
        {
          id: 'brain_training',
          text: t`Brain training`,
          icon: '🧠',
          eventData: 'brain training',
        },
        {
          id: 'family_friends',
          text: t`Family & Friends`,
          icon: '👪',
          eventData: 'family&friends',
        },
        { id: 'travel', text: t`Travel`, icon: '✈️', eventData: 'travel' },
        { id: 'other', text: t`Other`, icon: '📺', eventData: 'other' },
      ],
      showAfterScreen: {
        id: 'conditional_divider',
        type: 'conditional_divider',
        dividers: [
          {
            id: 'more_income',
            title: t`Advanced English speakers earn 63% more income.`,
            image: '/images/courses-onboarding/piggy-bank-savings.png',
            subtitle: t`If you’re a beginner, you can even <strong>double your income</strong> by learning`,
            bgColor: '#BEC8FF',
          },
          {
            id: 'education',
            title: t`English opens doors to education`,
            image: '/images/courses-onboarding/education.png',
            subtitle: t`There are programs from universities like Stanford, Harvard, and Oxford available FOR FREE in the Internet. All you need is good English to understand materials!`,
            bgColor: '#FFF050',
          },
          {
            id: 'brain_long_life',
            title: t`Language learning gives your brain a longer life!`,
            image: '/images/courses-onboarding/book-and-brain.png',
            bgColor: '#F5A0D2',
          },
        ],
      },
    },
    {
      id: 'user_language_level',
      type: 'single_choice',
      question: t`Your current target_language level?`,
      answerFromScreen: 'target_language',
      localizationKey: 'Your current lang level_',
      eventName: 'funnels_answered_level',
      category: 'survey',
      answersOptions: [
        {
          id: 'A1',
          text: t`Beginner`,
          icon: '😌',
          description: t`I know a few simple words`,
          eventData: 'beginner',
        },
        {
          id: 'A2',
          text: t`Elementary`,
          icon: '🧐️',
          description: t`I can talk about basic topics such as weather`,
          eventData: 'elementary',
        },
        {
          id: 'B1',
          text: t`Intermediate`,
          icon: '🤓',
          description: t`I can talk to foreigners a little`,
          eventData: 'intermediate',
        },
        {
          id: 'C1',
          text: t`Advanced`,
          icon: '😎',
          description: t`I am pretty fluent, but want more`,
          eventData: 'advanced',
        },
      ],
      showAfterScreen: {
        id: 'lessons_for_all_levels',
        type: 'divider',
        title: t`You’ll find lessons for all levels`,
        subtitle: t`Videos, live classes and more <strong>to help you understand</strong> every aspect of <strong>target_language</strong>`,
        answerFromScreen: 'target_language',
        localizationKey: 'Understand every aspect of_',
        image: '/images/courses-onboarding/online-learning-idea.png',
        bgColor: '#F5A0D2',
      },
    },
    {
      id: 'aspects_to_improve',
      type: 'multiple_choice',
      question: t`What aspects of target_language would you like to improve?`,
      description: t`You can choose more than one option`,
      localizationKey: 'What aspects of lang would you like to improve_',
      answerFromScreen: 'target_language',
      category: 'survey',
      eventName: 'funnels_answered_topics',
      answersOptions: [
        {
          id: 'spelling',
          text: t`Spelling`,
          icon: '🗣️',
          eventData: 'spelling',
        },
        {
          id: 'vocabulary',
          text: t`Vocabulary`,
          icon: '📖',
          eventData: 'vocabulary',
        },
        {
          id: 'punctuation',
          text: t`Punctuation`,
          icon: '🖊️',
          eventData: 'punctuation',
        },
        { id: 'tenses', text: t`Tenses`, icon: '⏳', eventData: 'tenses' },
        {
          id: 'phrasal_verbs',
          text: t`Phrasal verbs`,
          icon: '✨',
          eventData: 'phrasal verbs',
        },
        {
          id: 'language_styles',
          text: t`Language styles`,
          icon: '👔',
          eventData: 'language styles',
        },
      ],
      showAfterScreen: {
        id: 'divider',
        type: 'custom_divider',
        component: <Topics />,
        answerFromScreen: ['target_language', 'source_language'],
        expected: 'en',
      },
    },
    {
      id: 'how_much_time',
      type: 'single_choice',
      question: t`How much time are you ready to spend daily on studying target_language?`,
      answerFromScreen: 'target_language',
      localizationKey: 'Spend daily on studying_',
      category: 'survey',
      eventName: 'funnels_answered_time',
      answersOptions: [
        {
          id: '10_15_min',
          text: t`10-15 min`,
          eventData: '10-15 min',
          icon: '😐',
        },
        {
          id: '20_40_min',
          text: t`20-40 min`,
          eventData: '20-40 min',
          icon: '😊',
        },
        {
          id: '1_hour',
          text: t`1 hour`,
          eventData: 'hour',
          icon: '🥰',
        },
        {
          id: 'more_than_1_hour',
          text: t`>1 hour`,
          eventData: 'hour+',
          icon: '😍',
        },
      ],
      showAfterScreen: {
        id: 'no_waste_of_time',
        type: 'divider',
        title: t`No waste of time`,
        localizationKey: 'You need just a few minutes a day to improve your_',
        image: '/images/courses-onboarding/hour_glass.png',
        subtitle: t`You need just a few minutes a day to improve your target_language`,
        bgColor: '#C8DF87',
        answerFromScreen: 'target_language',
      },
    },
    {
      id: 'how_often_study',
      type: 'single_choice',
      question: t`How often would you like to study?`,
      eventName: 'funnels_answered_how_often',
      answersOptions: [
        {
          id: 'one_per_week',
          text: t`1 time per week`,
          eventData: 'once',
          icon: '😴',
        },
        {
          id: 'two_per_week',
          text: t`2 times per week`,
          eventData: 'twice',
          icon: '🧘🏻‍♂️',
        },
        {
          id: 'three_per_week',
          text: t`3 times per week`,
          eventData: '3 times',
          icon: '💃',
        },
        {
          id: 'four_per_week',
          text: t`4 times per week`,
          eventData: '4 times',
          icon: '🏃🏻‍♂️',
        },
        {
          id: 'five_per_week',
          text: t`5 times per week`,
          eventData: '5 times',
          icon: '🤯',
        },
      ],
    },
  ],
}

export default data
