import { mainHttpClient } from '@api/OpenApiClient'
import { paths } from '@api/schema/schema'

import { UseQueryOptions } from '@_types/index'

import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'

const GET_PROFILE_QUERY_KEY = '/v1/profiles'

type Profile =
  paths[typeof GET_PROFILE_QUERY_KEY]['get']['responses']['200']['content']['application/json']

const getProfile = async () => {
  const { data } = await mainHttpClient.get(GET_PROFILE_QUERY_KEY)

  return data
}

const useProfile = (
  config?: UseQueryOptions<
    paths[typeof GET_PROFILE_QUERY_KEY]['get'],
    Awaited<ReturnType<typeof getProfile>>,
    unknown,
    string
  >
) => useQueryFactory([GET_PROFILE_QUERY_KEY], getProfile)(config)

export { getProfile, useProfile, type Profile, GET_PROFILE_QUERY_KEY }
