import { useRouter } from 'next/router'
import { useEffect, useLayoutEffect } from 'react'

import { ENTRY_POINT, FLOW_ID, observeUtms } from '@constants_folder/common'

export const useSaveUtmsToLS = () => {
  const { query } = useRouter()

  useLayoutEffect(() => {
    const keys = Object.keys(query)

    // rewrite all utms in localStorage if url contains one of observable
    if (keys.some((key) => observeUtms.includes(key))) {
      observeUtms.forEach((key: string) => {
        localStorage.removeItem(key)
        if (keys.includes(key)) {
          localStorage.setItem(key, String(query[key]))
          sessionStorage.setItem(key, String(query[key]))
        }
      })
    }
  }, [query])
}

export const useSaveEntryPoint = () => {
  const { isReady, asPath, query } = useRouter()

  // save first page visited to local storage
  useEffect(() => {
    const shouldSaveEntryPoint = query[ENTRY_POINT] === 'true'

    if (isReady && shouldSaveEntryPoint) {
      localStorage.setItem(ENTRY_POINT, asPath)
    }
  }, [isReady, asPath, query])
}

export const getUtmsFromLS = () => {
  const utms: Record<string, any> = {}

  observeUtms.forEach((key: string) => {
    const value = localStorage.getItem(key)
    if (value) {
      utms[key] = value
    }
  })

  const flowId = localStorage.getItem(FLOW_ID)
  if (flowId) {
    utms.utm_flow = flowId
  }

  return utms
}

export const getUtmsFromSessionStorage = () => {
  const utms: Record<string, any> = {}

  observeUtms.forEach((key: string) => {
    const value = sessionStorage.getItem(key)
    if (value) {
      utms[key] = value
    }
  })

  return utms
}
