import { t } from '@lingui/macro'

const data = {
  quiz_id: 'short_onboarding',
  survey_type: 'common',
  language_test_type: 'common', // without this value we can't save quiz result in db
  mainQuestions: [
    {
      id: 'user_language_level',
      type: 'single_choice',
      question: t`What is your current English level?`,
      category: 'survey',
      eventName: 'funnels_answered_level',
      answersOptions: [
        {
          id: 'A1',
          text: t`Beginner`,
          icon: '👶',
          description: t`I know a few simple words and phrases`,
          eventData: 'beginner',
        },
        {
          id: 'A2',
          text: t`Elementary`,
          icon: '🙋‍♀️',
          description: t`I can talk about basic everyday topics`,
          eventData: 'elementary',
        },
        {
          id: 'B1',
          text: t`Pre-Intermediate`,
          icon: '😊',
          description: t`I can talk about everyday topics and some complex topics`,
          eventData: 'pre-intermediate',
        },
        {
          id: 'B2',
          text: t`Intermediate`,
          icon: '😎',
          description: t`I can talk about most topics, but I’m not very confident`,
          eventData: 'intermediate',
        },
        {
          id: 'C1',
          text: t`Upper Intermediate`,
          icon: '🤩',
          description: t`I can talk to foreigners quite confidently`,
          eventData: 'upper intermediate',
        },
        {
          id: 'C2',
          text: t`Advanced`,
          icon: '💪',
          description: t`I am pretty fluent, but want more`,
          eventData: 'advanced',
        },
      ],
    },
    {
      id: 'motivation',
      type: 'multiple_choice',
      question: t`What are your main goals for learning English?`,
      description: t`You can choose more than one option`,
      category: 'survey',
      eventName: 'funnels_answered_goals',
      answersOptions: [
        {
          id: 'career_job',
          text: t`Work/Career`,
          description: t`I want to do get ahead and impress my colleagues with fluent English.`,
          icon: '💼',
          eventData: 'work',
        },
        {
          id: 'travel',
          text: t`Travel`,
          description: t`I want to see exotic places and meet great people all over the globe.`,
          icon: '✈️',
          eventData: 'travel',
        },
        {
          id: 'school',
          text: t`Academic study`,
          description: t`I want to earn a cool hat and a cool degree by studying.`,
          icon: '🎓',
          eventData: 'academics',
        },
        {
          id: 'culture',
          text: t`Culture`,
          description: t`I want to embrace an English-speaking culture, media, and people.`,
          icon: '🇬🇧',
          eventData: 'culture',
        },
        {
          id: 'family_friends',
          text: t`Family & Friends`,
          description: t`I want to speak fluently with cool people and loved ones.,`,
          icon: '👪',
          eventData: 'family&friends',
        },
        {
          id: 'personal_development',
          text: t`Personal development`,
          description: t`I want to be a lifelong learner and English is a part of this journey.`,
          icon: '📍',
          eventData: 'personal development',
        },
      ],
    },
    {
      id: 'user_age',
      type: 'single_choice',
      question: t`How old are you?`,
      category: 'survey',
      eventName: 'funnels_answered_age',
      answersOptions: [
        {
          id: '16 - 24',
          text: '16-24',
          icon: '🐶',
          eventData: '16-24',
        },
        {
          id: '25 - 34',
          text: '25-34',
          icon: '🦊',
          eventData: '25-34',
        },
        {
          id: '35 - 44',
          text: '35-44',
          icon: '🐨',
          eventData: '35-44',
        },
        {
          id: '45 - 54',
          text: '45-54',
          icon: '🐯',
          eventData: '45-54',
        },
        {
          id: '55 - 65',
          text: '55-65',
          icon: '🐼',
          eventData: '55-65',
        },
        {
          id: '65+',
          text: '65+',
          icon: '🦁',
          eventData: '65+',
        },
        {
          id: 'Prefer not to say',
          text: t`Prefer not to say`,
          icon: '🕶',
          eventData: 'Prefer not to say',
        },
      ],
    },
    {
      id: 'learning_frequency',
      question: t`How often do you want to meet with your tutor?`,
      type: 'single_choice',
      category: 'survey',
      eventName: 'funnels_answered_how_often',
      answersOptions: [
        {
          icon: '🧘‍♀️',
          id: 'once',
          text: t`Once`,
          description: t`a week`,
          eventData: 'once',
        },
        {
          icon: '💃',
          id: '2 times',
          text: t`2 times`,
          description: t`per week`,
          eventData: 'twice',
        },
        {
          icon: '🏃‍♂️',
          id: '3 times',
          text: t`3 times`,
          description: t`per week`,
          eventData: '3 times',
        },
        {
          icon: '🤩',
          id: '4 times',
          text: t`4+ times`,
          description: t`per week`,
          eventData: '4 times',
        },
      ],
    },
    {
      id: 'practicing_time',
      question: t`How much time per day do you want to spend practicing English?`,
      type: 'single_choice',
      category: 'survey',
      eventName: 'funnels_answered_time',
      answersOptions: [
        {
          id: '15-30 minutes',
          text: t`Not much, 15-30 minutes`,
          icon: '️☺️',
          eventData: '15-30 minutes',
        },
        {
          id: 'hour',
          text: t`About an hour`,
          icon: '🥰',
          eventData: 'hour',
        },
        {
          id: 'hour+',
          text: t`More than an hour`,
          icon: '😍',
          eventData: 'hour+',
        },
      ],
    },
  ],
}

export default data
