import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { DISABLED_LOCALES } from '@promova/config'

const UseRouterLocales = () => {
  const { locales } = useRouter()

  return useMemo(
    () => locales?.filter((locale) => !DISABLED_LOCALES.includes(locale)),
    [locales]
  )
}

export default UseRouterLocales
