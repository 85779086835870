import { useEffect } from 'react'
import sourcebuster from 'sourcebuster'

import { useAppDispatch } from '@reduxStore/hooks'
import { setSourceBusterInited } from '@reduxStore/reducers/sourcebuster/sourceBusterSlice'

const useSourceBuster = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    sourcebuster.init({
      callback: () => {
        dispatch(setSourceBusterInited(true))
      },
    })
  }, [dispatch])
}

export default useSourceBuster
