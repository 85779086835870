import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import {
  HelpersActions,
  HelpersState,
} from '@reduxStore/reducers/helpers/types'


const reducers: SliceCaseReducers<HelpersState> = {
  [HelpersActions.SET_HELPERS]: (
    state: HelpersState,
    action: PayloadAction<Partial<HelpersState>>
  ) => ({
    ...state,
    ...action.payload,
  }),
}

export default reducers
