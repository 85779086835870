import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { HIDE_HEADER, WEB_VIEW } from '@constants_folder/storageKeys'

const useWebViewMode = () => {
  const [isWebViewMode, setWebViewMode] = useState(false)
  const [isHeaderHidden, setHeaderHidden] = useState(false)
  const { query } = useRouter()
  const turnOnWebViewMode = query[WEB_VIEW] === 'true'
  const shouldHideHeader = query[HIDE_HEADER] === 'true'

  useEffect(() => {
    const alreadyInWebViewMode = sessionStorage.getItem(WEB_VIEW) === 'true'
    const alreadyHiddenHeader = sessionStorage.getItem(HIDE_HEADER) === 'true'

    if (turnOnWebViewMode) {
      sessionStorage.setItem(WEB_VIEW, 'true')
    }

    if (shouldHideHeader) {
      sessionStorage.setItem(HIDE_HEADER, 'true')
    }

    if (turnOnWebViewMode || alreadyInWebViewMode) {
      setWebViewMode(true)
    }

    if (shouldHideHeader || alreadyHiddenHeader) {
      setHeaderHidden(true)
    }
  }, [turnOnWebViewMode, shouldHideHeader])

  return { isWebViewMode, isHeaderHidden }
}

export default useWebViewMode
