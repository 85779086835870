import * as amplitude from '@amplitude/analytics-browser'
import type { Result } from '@amplitude/analytics-types'

import {
  BLOCK_AMPLITUDE_ANALYTICS,
  BLOCK_ANALYTICS,
  BLOCK_FB_ANALYTICS,
  BLOCK_GOOGLE_ANALYTICS,
  BLOCK_PINTEREST_ANALYTICS,
  BLOCK_SNAP_PIXEL_ANALYTICS,
  BLOCK_TIKTOK_ANALYTICS,
  BLOCK_TWITTER_ANALYTICS,
} from '@promova/config'

import getProductLtv from '@api/getProductLtv'

import { Event } from '@constants_folder/analyticsEvents'
import { BACKEND_ERROR, IS_PRODUCTION } from '@constants_folder/common'

import {
  PinterestEventNames,
  SnapChatEventNames,
  TwitterEventNames,
} from '@_types/analytics'
import { Total } from '@_types/index'

import getSessionStorageBoolean from '@utils/getSessionStorageBoolean'

import { getPriceValue } from './getPriceValue'

export interface ConfigData {
  place?: string
  path?: string
  page_path?: string
  text?: string
  type?: string
  button_number?: string | number
  redirect?: boolean | string
  redirect_to?: string
  mail?: string

  [key: string]: any
}

export interface PinterestConfig {
  eventName: PinterestEventNames
  data?: ConfigData
}

export interface SnapPixelConfig {
  eventName: SnapChatEventNames
  data?: ConfigData
}

export interface Config {
  eventName: keyof typeof Event | string
  data?: ConfigData
  userId?: string
}

interface AnalyticsSettings {
  blockAnalytics: boolean
  blockFBAnalytics: boolean
  blockGoogleAnalytics: boolean
  blockAmplitudeAnalytics: boolean
  blockSnapchatAnalytics: boolean
  blockTwitterAnalytics: boolean
  blockTiktokAnalytics: boolean
}

export const getAnalyticsSettings = (): AnalyticsSettings => {
  const blockAnalytics = getSessionStorageBoolean(BLOCK_ANALYTICS)
  const blockFBAnalytics = getSessionStorageBoolean(BLOCK_FB_ANALYTICS)
  const blockGoogleAnalytics = getSessionStorageBoolean(BLOCK_GOOGLE_ANALYTICS)
  const blockAmplitudeAnalytics = getSessionStorageBoolean(
    BLOCK_AMPLITUDE_ANALYTICS
  )
  const blockSnapchatAnalytics = getSessionStorageBoolean(
    BLOCK_SNAP_PIXEL_ANALYTICS
  )
  const blockTwitterAnalytics = getSessionStorageBoolean(
    BLOCK_TWITTER_ANALYTICS
  )
  const blockTiktokAnalytics = getSessionStorageBoolean(BLOCK_TIKTOK_ANALYTICS)

  return {
    blockAnalytics,
    blockFBAnalytics,
    blockGoogleAnalytics,
    blockAmplitudeAnalytics,
    blockSnapchatAnalytics,
    blockTwitterAnalytics,
    blockTiktokAnalytics,
  }
}

const isSendProdEvents = process.env.NEXT_PUBLIC_SEND_PROD_EVENTS === 'true'

export const sendSnapPixelAnalytics = (config: SnapPixelConfig) => {
  const blockSnapPixelAnalytics =
    sessionStorage.getItem(BLOCK_SNAP_PIXEL_ANALYTICS) === 'true'

  if (!IS_PRODUCTION || blockSnapPixelAnalytics) return

  const { snaptr: snapPixel } = window as any
  const { eventName, data } = config

  try {
    if (snapPixel) {
      snapPixel('track', eventName, data)
    }
  } catch {
    // don't throw error outside block
  }
}

export const sendAnalytics = (config: Config): void => {
  const {
    blockAnalytics,
    blockFBAnalytics,
    blockGoogleAnalytics,
    blockAmplitudeAnalytics,
  } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics) return

  const { eventName, data } = config

  const { fbq: facebook, dataLayer } = window as any

  if (dataLayer && isSendProdEvents && !blockGoogleAnalytics) {
    dataLayer.push({ event: eventName, ...data })
  }

  if (facebook && isSendProdEvents && !blockFBAnalytics) {
    facebook('trackCustom', eventName, data)
  }

  if (!blockAmplitudeAnalytics) {
    amplitude.track(eventName as string, data)
  }
}

export const sendToFacebook = (config: Config): void => {
  const { blockAnalytics, blockFBAnalytics } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics) return
  const { eventName, data } = config
  const { fbq: facebook } = window as any

  if (facebook && isSendProdEvents && !blockFBAnalytics) {
    facebook('trackCustom', eventName, data)
  }
}

type TiktokEventProps = {
  eventName: string
  data: {
    event_id: string
    query?: string
    currency?: string
    content_id?: string
    price?: number
    value?: number // LTV
  }
}

export const sendTiktokEvent = ({
  eventName,
  data,
}: TiktokEventProps): void => {
  const { blockAnalytics, blockTiktokAnalytics } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics || blockTiktokAnalytics) return
  const { ttq: tiktok } = window as any

  if (tiktok) {
    try {
      tiktok.track(eventName, data)
    } catch {
      //
    }
  }
}

type SendTwitterEventProps = {
  eventName: TwitterEventNames
  data?: {
    conversion_id: string
    email_address: string
    value?: number
    currency?: string
  }
}

export const sendTwitterEvent = (props: SendTwitterEventProps): void => {
  const { blockAnalytics, blockTwitterAnalytics } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics || blockTwitterAnalytics) return
  const { twq: twitter } = window as any
  const { eventName, data } = props

  if (twitter) {
    try {
      twitter('event', eventName, data)
    } catch {
      //
    }
  }
}

export interface PageViewConfig {
  eventName?: string
  data?: ConfigData
  userId?: string
}

export const trackPageView = (config: PageViewConfig): void => {
  const { data, eventName = 'page_view' } = config
  const {
    blockAnalytics,
    blockFBAnalytics,
    blockGoogleAnalytics,
    blockAmplitudeAnalytics,
    blockTwitterAnalytics,
    blockTiktokAnalytics,
  } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics) return

  const { fbq: facebook, dataLayer, ttq: tiktok } = window as any

  if (dataLayer && isSendProdEvents && !blockGoogleAnalytics) {
    dataLayer.push({ event: eventName, ...data })
  }

  if (facebook && isSendProdEvents && !blockFBAnalytics) {
    facebook('trackCustom', eventName, data)
  }

  if (!blockTwitterAnalytics) {
    sendTwitterEvent({
      eventName: TwitterEventNames.PAGE_VIEW,
    })
  }

  if (tiktok && !blockTiktokAnalytics) {
    try {
      tiktok.track('ViewContent')
    } catch {
      //
    }
  }

  if (!blockAmplitudeAnalytics) {
    amplitude.track(eventName, data)
  }
}

export const sendPinterestAnalytics = (config: PinterestConfig) => {
  const blockSnapPixelAnalytics =
    sessionStorage.getItem(BLOCK_PINTEREST_ANALYTICS) === 'true'

  if (process.env.NODE_ENV !== 'production' || blockSnapPixelAnalytics) return

  const { pintrk: pinterest } = window as any
  const { eventName, data } = config

  try {
    if (pinterest) {
      pinterest('track', eventName, data)
    }
  } catch {
    // don't throw error outside block
  }
}

export enum FacebookDefaultEvents {
  CompleteRegistration = 'CompleteRegistration',
  InitiateCheckout = 'InitiateCheckout',
  AddPaymentInfo = 'AddPaymentInfo',
  Purchase = 'Purchase',
  ViewContent = 'ViewContent',
}

type Props = {
  eventName: FacebookDefaultEvents
  data: Record<string, string | boolean | number | undefined | null>
}

export const sendDefaultFacebookEvent = ({ eventName, data }: Props): void => {
  const { blockAnalytics, blockFBAnalytics } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics) return

  const SEND_PROD_EVENTS = process.env.NEXT_PUBLIC_SEND_PROD_EVENTS === 'true'

  const { fbq } = window as any

  if (fbq && SEND_PROD_EVENTS && !blockFBAnalytics) {
    fbq('track', eventName, data)
  }
}

export const sendECommerceAnalytics = (config: Config): void => {
  const { blockAnalytics, blockGoogleAnalytics } = getAnalyticsSettings()

  if (!IS_PRODUCTION || blockAnalytics) return

  const { eventName, data } = config

  const { dataLayer } = window as any

  if (dataLayer && isSendProdEvents && !blockGoogleAnalytics) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({ event: eventName, ecommerce: data })
  }
}

export const sendToAmplitude = (config: Config): void => {
  const { blockAnalytics, blockAmplitudeAnalytics, blockTiktokAnalytics } =
    getAnalyticsSettings()
  const isAnalyticsBlocked = !IS_PRODUCTION || blockAnalytics

  if (isAnalyticsBlocked) return

  const { eventName, data } = config
  const { ttq: tiktok } = window as any

  if (tiktok && !blockTiktokAnalytics) {
    try {
      tiktok.track(eventName)
    } catch {
      //
    }
  }

  if (!blockAmplitudeAnalytics) {
    amplitude.track(eventName as string, data)
  }
}

export interface PaymentData {
  provider: string
  payment_type?: string

  [key: string]: any
}

interface PaymentConfig {
  eventName: string
  total: Total
  orderId: string
  data: PaymentData
  pv?: number
}

export const sendPaymentOnlyProd = async (
  config: PaymentConfig
): Promise<void> => {
  const {
    blockAnalytics,
    blockAmplitudeAnalytics,
    blockGoogleAnalytics,
    blockFBAnalytics,
    blockTiktokAnalytics,
  } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics) return

  const { eventName, data, total, orderId } = config

  const { productId } = total
  const price = data.amount / 100
  const value = total ? getPriceValue(total).percentLtv : price
  const fullValue = total ? getPriceValue(total).fullLtv : price

  const ltvGoogleFromBE = await getProductLtv({
    productId: total.productId,
    destination: 'google',
  })
  const ltvFacebookFromBE = await getProductLtv({
    productId: total.productId,
  })
  const ltvPinterestFromBE = await getProductLtv({
    productId: total.productId,
    destination: 'pinterest',
  })
  const ltvSnapChatFromBE = await getProductLtv({
    productId: total.productId,
    destination: 'snapchat',
  })

  const googleLtv = ltvGoogleFromBE || fullValue
  const facebookLtv = ltvFacebookFromBE || value
  const pinterestLtv = ltvPinterestFromBE || fullValue

  const { fbq, dataLayer, ttq: tiktok } = window as any

  sendSnapPixelAnalytics({
    eventName: SnapChatEventNames.PURCHASE,
    data: {
      currency: total.currencyCode,
      price: ltvSnapChatFromBE || value,
      transaction_id: orderId,
      client_dedup_id: orderId,
    },
  })

  sendPinterestAnalytics({
    eventName: PinterestEventNames.CHECKOUT,
    data: {
      value: pinterestLtv,
      order_quantity: 1,
      currency: total?.currencyCode,
      line_items: [
        {
          product_name: total.productId,
          product_id: total.productId,
          product_category: total.productId,
        },
      ],
    },
  })

  if (dataLayer && isSendProdEvents && !blockGoogleAnalytics) {
    dataLayer.push({
      event: 'conversion',
      send_to: 'AW-695606285/Lh72CLLtlcACEI242MsC',
      value: price,
      currency: data.currency,
      transaction_id: productId,
    })

    dataLayer.push({
      event: 'purchase',
      value: price,
      ltv: googleLtv,
      currency: data.currency,
      transaction_id: data?.order_id,
      items: [
        {
          id: productId,
          name: productId,
          quantity: 1,
          price,
        },
      ],
      user_email: data?.user_email,
    })

    dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: data.order_id, // Transaction ID. Required for purchases and refunds.
            revenue: price,
            currency: data.currency,
          },
          products: [
            {
              // List of productFieldObjects.
              name: productId,
              id: productId,
              price,
              quantity: 1,
            },
          ],
        },
      },
    })
  }

  if (fbq && isSendProdEvents && !blockFBAnalytics) {
    fbq('trackCustom', eventName, data)
    fbq(
      'track',
      'Purchase',
      {
        value: facebookLtv,
        currency: data.currency,
        content_ids: [productId],
        content_type: 'product',
      },
      { eventID: data.order_id }
    )
  }

  if (tiktok && !blockTiktokAnalytics) {
    try {
      tiktok.track(eventName)
    } catch {
      //
    }
  }

  if (!blockAmplitudeAnalytics) {
    amplitude.track(eventName, { ...data, amount: price })
  }
}

export const authCompleted = (config: Config): void => {
  const { blockAnalytics, blockGoogleAnalytics } = getAnalyticsSettings()
  if (!IS_PRODUCTION || blockAnalytics) return
  const { eventName, data } = config

  const { dataLayer } = window as any

  if (dataLayer && !blockGoogleAnalytics) {
    dataLayer.push({
      event: eventName,
      user_id: data?.userId,
    })
  }

  sendSnapPixelAnalytics({ eventName: SnapChatEventNames.SIGN_UP })

  sendPinterestAnalytics({ eventName: PinterestEventNames.SIGN_UP })
}

export const setExternalUserId = (userId: string): void => {
  const { fbq } = window as any

  try {
    if (fbq && isSendProdEvents) {
      fbq('init', process.env.NEXT_PUBLIC_FB_PIXEL_ID, {
        external_id: userId,
      })
    }
  } catch (error) {
    // don't throw error outside block
  }
}

export const setUserProperties = (
  userProperties: Record<string, any>
): void => {
  const identify = new amplitude.Identify()
  Object.entries(userProperties).forEach(([key, value]) => {
    identify.set(key, value)
  })
  amplitude.identify(identify)
}

const ERROR_SEND_DELAY = 20000 // 20sec

export const sendAxiosErrorAnalytics = (err: Record<string, any>) => {
  if (typeof window === 'undefined') {
    return
  }

  if (err?.response?.status === 404) {
    throw err
  }

  const errorStatus = err?.response?.status
  const place = window?.location?.href
  const date = Date.now()
  const savedError = sessionStorage.getItem(BACKEND_ERROR)

  switch (true) {
    case !!savedError: {
      let wasError
      try {
        wasError = JSON.parse(savedError as string)
      } catch {
        break
      }
      const wasStatus = wasError?.errorStatus
      const wasPlace = wasError?.place
      const wasDate = wasError?.date
      if (!wasDate) {
        break
      }
      const now = Date.now()
      const timePassed = now - wasDate
      const shouldSendError =
        wasStatus === errorStatus &&
        wasPlace === place &&
        timePassed > ERROR_SEND_DELAY
      if (!shouldSendError) {
        throw err
      }
      break
    }

    default: {
      break
    }
  }

  sessionStorage.setItem(
    BACKEND_ERROR,
    JSON.stringify({ errorStatus, place, date })
  )
  sendAnalytics({
    eventName: 'gen_backend_error',
    data: {
      error: errorStatus,
      url: err?.response?.request?.responseURL,
      place,
    },
  })

  throw err
}

interface SaveConfig extends Config {
  callback: (res: Result) => any
}

export const saveToAmplitude = (config: SaveConfig): void => {
  const { eventName, data, callback } = config
  const { ttq: tiktok } = window as any
  const { blockTiktokAnalytics } = getAnalyticsSettings()

  if (tiktok && !blockTiktokAnalytics) {
    try {
      tiktok.track(eventName)
    } catch {
      //
    }
  }

  amplitude.track(eventName as string, data).promise.then(callback)
}

export const setAmplitudeUserId = (userId: string | undefined) => {
  amplitude.setUserId(userId)
}

export interface CheckoutAnalyticsData extends Config {
  data: {
    flow: string
    cta: string | null | undefined
    product_id: string
    price: number
    is_trial: boolean
    currency: string
    place?: string
    is_login: boolean
    product_term?: string
    product_category?: string
  }
}

export const sendCheckoutAnalytics = (config: CheckoutAnalyticsData) => {
  sendAnalytics(config)
}

export const sendAnalyticsOncePerSession = (
  params: Config & {
    key?: string
  }
) => {
  const { key, ...config } = params

  const sessionStorageKey = key || config.eventName

  const isEvenSent = sessionStorage.getItem(sessionStorageKey) === 'true'

  if (!isEvenSent) {
    sendAnalytics(config)

    sessionStorage.setItem(sessionStorageKey, 'true')
  }
}

type SendAnalyticsOncePerSessionProps = {
  key: string
  value: any
  split_analytics: {
    send_event: boolean
    send_user_prop: boolean
  }
}

const isAmplitudeInitialized = (): boolean =>
  !!(window as any)._store?.getState()?.amplitude?.amplitudeInited

const waitForAmplitudeInit = (callback: () => void) => {
  const unsubscribe = (window as any)._store?.subscribe(() => {
    if (isAmplitudeInitialized()) {
      callback()
      unsubscribe()
    }
  })
}

export const sendJoinABTestOncePerSession = ({
  key,
  value,
  split_analytics,
}: SendAnalyticsOncePerSessionProps): void => {
  const isSendABTest = split_analytics?.send_event

  if (isAmplitudeInitialized()) {
    if (isSendABTest) {
      sendAnalyticsOncePerSession({
        eventName: 'gen_joined_ab_test',
        data: {
          param_name: key,
          param_value: value,
        },
        key,
      })
    }

    const isSetUserProp = split_analytics?.send_user_prop

    if (isSetUserProp) {
      setUserProperties({
        [`ab_test.${key}`]: value,
      })
    }
  } else {
    waitForAmplitudeInit(() => {
      sendJoinABTestOncePerSession({
        key,
        value,
        split_analytics,
      })
    })
  }
}
